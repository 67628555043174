import { FunctionComponent, useEffect, useState } from "react";
import { chakra, Box } from "@chakra-ui/react";
import { TreeNodeItem } from "./TreeNodeItem";
import { findNodeByKey, resetSelection, getNodeSelection, setSelection } from "./tree-utils";
import { TreeNode } from "../../api";

interface Props {
    nodes?: TreeNode[];
    onSelectionChanged: (selection: string[] | string) => void;
    defaultValue?: string | string[] | undefined;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    singleSelection?: boolean;
}
export const TreeSelect: FunctionComponent<Props> = ({
    nodes = [],
    onSelectionChanged,
    defaultValue,
    isDisabled = false,
    isReadOnly = false,
    singleSelection = false,
}) => {
    const [treeNodes, setTreeNodes] = useState<TreeNode[]>(nodes);

    useEffect(() => {
        setTreeNodes(nodes);
    }, [nodes]);

    useEffect(() => {
        if (defaultValue) {
            setSelection(nodes, defaultValue);
        }
        const deepCopy = JSON.parse(JSON.stringify(nodes));
        setTreeNodes(deepCopy);
    }, [nodes, defaultValue]);

    const toggleSelect = (key: string) => {
        const n = findNodeByKey(key, treeNodes);
        if (!n) {
            console.log(`Couldn't find node with key: ${key}`);
            return;
        }
        if (singleSelection) {
            resetSelection(treeNodes);
        }
        n.selected = !n.selected;
        const deepCopy = JSON.parse(JSON.stringify(treeNodes));
        setTreeNodes(deepCopy);

        const currSelection = getNodeSelection(deepCopy);
        if (singleSelection) {
            const selection = currSelection.length > 0 ? currSelection[0].key : "";
            onSelectionChanged(selection);
        } else {
            onSelectionChanged(currSelection.map(x => x.key));
        }
    };

    const toggleExpand = (key: string) => {
        const n = findNodeByKey(key, treeNodes);
        if (!n) {
            console.log(`Couldn't find node with key: ${key}`);
            return;
        }
        n.expanded = !n.expanded;
        const deepCopy = JSON.parse(JSON.stringify(treeNodes));
        setTreeNodes(deepCopy);
    };

    const createRootChild = (node: TreeNode, last: boolean): JSX.Element => (
        <TreeNodeItem
            key={node.key}
            node={node}
            last={last}
            toggleSelect={toggleSelect}
            toggleExpand={toggleExpand}
            isReadOnly={isReadOnly ?? false}
            isDisabled={isDisabled ?? false}
        />
    );

    const rootChildren = treeNodes.map((node: TreeNode, index: number) => createRootChild(node, index === nodes.length - 1));

    const treeModel =
        treeNodes && treeNodes.length > 0 ? (
            <chakra.ul
                role="tree"
                sx={{
                    listStyleType: "none",
                }}
            >
                {rootChildren}
            </chakra.ul>
        ) : null;

    return <Box>{treeModel}</Box>;
};

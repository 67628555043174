import { FunctionComponent } from "react";
import { BaseReportPage } from "../EngagementReportPage/BaseReportPage";
import { Box, Flex } from "@chakra-ui/react";
import { Button } from "am-tax-fe-core";
import { IconPlus } from "@tabler/icons-react";
import { ReportArea } from "../../auth/index.ts";
import { useEngagementAreaPermissions } from "../../hooks/index.ts";
import { useParams } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import { CreateProjectWorkspaceArgs, useCreateProjectWorkspace, useProject } from "../../api";
import { Page } from "../Page.tsx";

const ProjectRequestPage: FunctionComponent = () => {
    return <BaseReportPage />;
};

export default ProjectRequestPage;

export enum AccessStatus {
    NotRequested = 1,
    Requested = 2,
    Denied = 3,
    Accepted = 4,
}

export const AccessRequestStatuses = Object.values(AccessStatus).filter(v => !isNaN(v as AccessStatus)) as AccessStatus[];

type AccessRequestStatusDetails = { displayName: string };

export const AccessRequestStatusLookup: Record<AccessStatus, AccessRequestStatusDetails> = {
    [AccessStatus.NotRequested]: { displayName: "Not Requested" },
    [AccessStatus.Requested]: { displayName: "Access Requested" },
    [AccessStatus.Denied]: { displayName: "No Access" },
    [AccessStatus.Accepted]: { displayName: "Accepted" },
};

export const displayAccessRequestStatus = (accessRequestStatus: AccessStatus) => {
    return AccessRequestStatusLookup[accessRequestStatus].displayName;
};

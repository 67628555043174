import {
    useSaveEngagementTaxNotice,
    useEngagementTaxNotice,
    useEngagement,
    useUploadDocumentToEngagementTaxNotice,
    useDeleteEngagementTaxNotice,
    TaxNotice,
    invalidateDocumentCacheForEngagementAndFolder,
    invalidateEngagementTaxNoticeQueries,
} from "../../api";
import { Mutation } from "../../util/queryUtils.ts";
import { useMemo } from "react";
import { ProjectEngagement, TaxNoticeFormProps } from "../EngagementTaxNoticePage/TaxNoticeForm.tsx";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

export function useTaxNoticeFormForEngagement(engagementId: string, taxNoticeId: string): TaxNoticeFormProps {
    const navigate = useNavigate();
    const projectEngagementQuery = useEngagement(engagementId, undefined);
    const saveTaxNoticeQuery = useSaveEngagementTaxNotice();
    const deleteTaxNoticeQuery = useDeleteEngagementTaxNotice();

    const taxNoticeQuery = useEngagementTaxNotice(engagementId, taxNoticeId);
    const uploadFileToTaxNoticeQuery = useUploadDocumentToEngagementTaxNotice();

    const saveTaxNoticeMutation = useMemo(() => new Mutation(saveTaxNoticeQuery), [saveTaxNoticeQuery]);
    const deleteTaxNoticeMutation = useMemo(() => new Mutation(deleteTaxNoticeQuery), [deleteTaxNoticeQuery]);
    const uploadFileToTaxNoticeMutation = useMemo(() => new Mutation(uploadFileToTaxNoticeQuery), [uploadFileToTaxNoticeQuery]);

    const queryClient = useQueryClient();
    const onSaveComplete = (projectOrEngagement: ProjectEngagement, taxNotice: TaxNotice, filesUploaded?: boolean) => {
        const currFolderId = projectOrEngagement.clientDocumentsRootFolderId;
        invalidateEngagementTaxNoticeQueries(queryClient, taxNotice.engagementId || "", taxNotice.id || "");
        if (filesUploaded) {
            invalidateDocumentCacheForEngagementAndFolder(queryClient, projectOrEngagement.engagementId, currFolderId);
        }
    };

    const navigateBack = () => {
        navigate(`/engagement/${engagementId}/taxnotices/`, { relative: "path" });
    };

    return {
        projectEngagementQuery,
        taxNoticeQuery,
        saveTaxNoticeMutation,
        deleteTaxNoticeMutation,
        uploadFileToTaxNoticeMutation,
        navigateBack,
        onSaveComplete,
    };
}

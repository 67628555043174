import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import { useEngagementAreaPermissions } from "../../hooks/index.ts";
import { ReportArea } from "../../auth/index.ts";
import { useEngagement, useCreateEngagementWorkspace, useCurrentUser, useGetEngagementWorkspace } from "../../api/apiQueries.ts";
import { CreateEngagementWorkspaceArgs } from "../../api/api.ts";

import { BaseReportsPage } from "./BaseReportsPage.tsx";
import EnableReportsPage from "./EnableReportsPage.tsx";
import { PageLoading } from "am-tax-fe-core";

export const EngagementReportsPage: FunctionComponent = () => {
    const { engagementId } = useParams();
    const areaPermissions = useEngagementAreaPermissions(ReportArea, engagementId);
    const engagement = useEngagement(engagementId, undefined);
    //const workspace = useGetEngagementWorkspace(engagementId!);
    const { data: workspace, isLoading: isLoading } = useGetEngagementWorkspace(engagementId!);
    const { mutateAsync: createWorkspaceAsync, isPending } = useCreateEngagementWorkspace();
    const currentUser = useCurrentUser().data;

    const handleEnableReportsClick = async () => {
        const args: CreateEngagementWorkspaceArgs = {
            engagementId: engagementId!,
            workspaceName: engagement.data?.name ?? "",
        };
        await createWorkspaceAsync(args);
    };

    if (isLoading) {
        return <PageLoading text={"Page loading..."} />;
    }
    return (
        <>
            {!workspace?.workspaceId ? (
                <EnableReportsPage
                    hasPermission={!!areaPermissions}
                    onClick={handleEnableReportsClick}
                    isLoading={isPending}
                    isInternaluser={currentUser!.isInternal}
                />
            ) : (
                <BaseReportsPage engagementId={engagementId || ""} areaPermissions={areaPermissions} isInternaluser={currentUser!.isInternal} />
            )}
        </>
    );
};

export default EngagementReportsPage;

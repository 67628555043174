import { ErrorResponse, ErrorResponseBody, FileContainer, checkForConfig, getMiddlewareConfiguration } from "am-tax-fe-core";
import { MiddlewareCallbackParams } from "openapi-fetch";

/**
 * this is a little bit of a hack that allows reuse of middlewares for xhr requests
 */
export function acquireAuthTokenFromMiddlewareHeaders() {
    checkForConfig();

    const middlewares = getMiddlewareConfiguration();

    return new Promise<string>((res, rej) => {
        let request = new Request("");
        if (middlewares) {
            for (const m of middlewares) {
                if (m && typeof m === "object" && typeof m.onRequest === "function") {
                    try {
                        const result = m.onRequest({ request } as MiddlewareCallbackParams);
                        if (result instanceof Request) {
                            request = result;
                        }
                        if (result instanceof Promise) {
                            result.then(x => {
                                const authToken = x?.headers.get("Authorization") || "";
                                res(authToken);
                            });
                        }
                    } catch (e) {
                        rej(e);
                    }
                }
            }
        }
    });
}

export function uploadFile<T, E extends ErrorResponse>(baseUrl: string, url: string, fileContainer: FileContainer<E>): Promise<T> {
    checkForConfig();

    return new Promise<T>((res, rej) => {
        //sending the first progress event immediately even though we haven't technically started the upload lets the users know that we are working on uploading this file.
        const initialEvent = new ProgressEvent("upload", { lengthComputable: false });
        fileContainer.callbacks.progress(initialEvent);

        acquireAuthTokenFromMiddlewareHeaders()
            .then(authToken => {
                const absoluteUrl = `${baseUrl}${url}`;
                const xhr = new XMLHttpRequest();
                xhr.open("POST", absoluteUrl);

                if (authToken) {
                    xhr.setRequestHeader("Authorization", authToken);
                }

                xhr.addEventListener("load", () => {
                    if (200 <= xhr.status && xhr.status < 300) {
                        let result = {} as T;
                        try {
                            result = JSON.parse(xhr.responseText) as T;
                        } catch (e) {
                            console.log(`The success response from ${url} was not valid JSON`, e);
                        }
                        fileContainer.callbacks.success();
                        res(result);
                    } else {
                        let result = {} as ErrorResponseBody;
                        try {
                            result = JSON.parse(xhr.responseText) as ErrorResponseBody;
                        } catch (e) {
                            console.log(`The error response from ${url} was not valid JSON`, e);
                        }
                        fileContainer.callbacks.error({ status: xhr.status, ...result } as E);
                        rej(result);
                    }
                });

                xhr.addEventListener("progress", evt => {
                    fileContainer.callbacks.progress(evt);
                });

                xhr.addEventListener("error", evt => {
                    rej(evt);
                });

                const formData = new FormData();
                formData.append("file", fileContainer.file);

                // if there are additional values added to the FileContainer metaData attribute, add them to the form data
                if (fileContainer.metaData) {
                    for (const key of Object.keys(fileContainer.metaData)) {
                        formData.append(key, fileContainer.metaData[key]);
                    }
                }
                xhr.send(formData);
            })
            .catch(rej);
    });
}

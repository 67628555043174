import { FunctionComponent, ReactNode } from "react";
import { useParams } from "react-router-dom";
import { Permission, SecurityArea } from "../../auth";
import { useProjectAppPermissions } from "../../hooks";
import { RouteGuard } from "../EngagementRouteGuard/RouteGuard.tsx";

export interface RouteGuardProps {
    securityArea?: SecurityArea;
    hasAny?: Permission[];
    render: ReactNode;
}

export const ProjectRouteGuard: FunctionComponent<RouteGuardProps> = ({ securityArea, hasAny, render }) => {
    const { engagementId, projectId } = useParams();
    const appPermissions = useProjectAppPermissions(engagementId, projectId);

    if (!engagementId || !projectId) {
        throw new Error("ProjectRouteGuard: engagementId and projectId are required.");
    }

    return <RouteGuard appPermissions={appPermissions} securityArea={securityArea} hasAny={hasAny} render={render} />;
};

import { FunctionComponent } from "react";
import { Flex } from "@chakra-ui/react";
import { FileContainer } from "am-tax-fe-core";

import { Document } from "../../api";
import { DocumentTable } from "./DocumentTable";
import { UploadPanel } from "./UploadPanel";

interface Props {
    engagementId: string;
    documents: Document[] | null | undefined;
    immediateUpload?: boolean;
    fileQueue: FileContainer[];
    setFileQueue: (q: FileContainer[]) => void;
    uploadFile: (q: FileContainer) => void;
    onUploadComplete: () => void;
    canUpload: boolean;
}

const DocumentListPanel: FunctionComponent<Props> = ({
    engagementId,
    documents,
    immediateUpload,
    fileQueue,
    setFileQueue,
    uploadFile,
    onUploadComplete,
    canUpload,
}) => {
    return (
        <Flex direction="column" gap={5}>
            {documents && documents.length > 0 && <DocumentTable engagementId={engagementId} documents={documents} />}
            {canUpload && (
                <UploadPanel
                    immediateUpload={immediateUpload}
                    fileQueue={fileQueue}
                    setFileQueue={setFileQueue}
                    uploadFile={uploadFile}
                    onUploadComplete={onUploadComplete}
                />
            )}
        </Flex>
    );
};

export default DocumentListPanel;

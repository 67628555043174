import { FC, PropsWithChildren, useMemo } from "react";
import { ClickableTopNavItem, Page as CorePage, PageProps as CorePageProps } from "am-tax-fe-core";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { ApplicationRole, RoleNames, useHasAccess } from "../hooks";
import { MenuItem } from "@chakra-ui/react";
import { Engagement, Project, useClientEngagements, useProject } from "../api";
import { isEngagement, isProject } from "../util/engagementUtils.ts";
import { IconUserCircle, IconSettings } from "@tabler/icons-react";
import { AccessStatus } from "../enums/AccessStatus.ts";
import { useAtom } from "jotai";
import { clientIdAtom } from "../atoms/atoms.ts";

export type PageProps = PropsWithChildren<Omit<CorePageProps, "location" | "menuItems">> & { subTitle?: string | undefined };

export const Page: FC<PageProps> = ({ children, subTitle, ...pageProps }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { identity } = useHasAccess();

    const isDeveloper = useMemo(() => (identity?.roles ?? []).map(r => RoleNames[r]).includes(RoleNames[ApplicationRole.developer]), [identity?.roles]);
    const menuItems = useMemo(() => {
        const items = [
            <MenuItem
                key={"myAccount"}
                icon={<IconUserCircle />}
                onClick={() => {
                    navigate("/myAccount");
                }}
            >
                My Account
            </MenuItem>,
        ];
        if (isDeveloper) {
            items.push(
                <MenuItem key={"profile"} icon={<IconSettings />} onClick={() => navigate("/profile")}>
                    Profile Settingsf
                </MenuItem>,
            );
        }
        return items;
    }, [isDeveloper, navigate]);

    const matchesEngagement = useMatch("/engagement/:engagementId/*");
    const engagementId = matchesEngagement?.params?.engagementId;

    const matchesProject = useMatch("/engagement/:engagementId/project/:projectId/*");
    const projectId = matchesProject?.params?.projectId;

    const [clientIdFromAtom] = useAtom(clientIdAtom);
    const { data } = useClientEngagements(clientIdFromAtom);
    const engagement = data?.engagements?.find(x => x.engagementId === engagementId);

    const projectQuery = useProject(engagementId, projectId);
    const project = projectQuery.data;

    const projectOrEngagement: Project | Engagement | undefined = project ?? engagement;
    const isEngagementEntity = isEngagement(projectOrEngagement);
    const isProjectEntity = isProject(projectOrEngagement);

    const breadcrumbs: ClickableTopNavItem[] = [];

    let engagementBreadcrumb: ClickableTopNavItem | undefined;
    if (projectOrEngagement && isEngagementEntity) {
        engagementBreadcrumb = {
            name: `${projectOrEngagement.projectCode}-${projectOrEngagement.name}`,
            onClick: () => navigate(`/engagement/${projectOrEngagement.engagementId}/dashboard`),
        };
    } else if (projectOrEngagement && isProjectEntity) {
        const eng = data?.engagements?.find(x => x.engagementId === projectOrEngagement.engagementId);
        if (eng) {
            engagementBreadcrumb = {
                name: `${eng.projectCode}-${eng.name}`,
                onClick: () => {
                    if (eng.accessStatus === AccessStatus.Accepted) navigate(`/engagement/${projectOrEngagement.engagementId}/dashboard`);
                },
            };
        }
    }
    if (engagementBreadcrumb) {
        breadcrumbs.push(engagementBreadcrumb);
    }

    let projectBreadcrumb: ClickableTopNavItem | undefined;
    if (projectOrEngagement && isProjectEntity) {
        const proj = projectOrEngagement as Project;
        projectBreadcrumb = {
            name: `${proj.projectCode}-${proj.name}`,
            onClick: () => navigate(`/engagement/${proj.engagementId}/project/${proj.projectId}/dashboard`),
        };
    }
    if (projectBreadcrumb) {
        breadcrumbs.push(projectBreadcrumb);
    }

    return (
        <CorePage
            location={location}
            topNavProps={{
                buildNumber: import.meta.env.VITE_BUILD_NUMBER,
                breadcrumbs,
                subTitle,
            }}
            menuItems={menuItems}
            {...pageProps}
        >
            {children}
        </CorePage>
    );
};

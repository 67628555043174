import { FunctionComponent, useState } from "react";

import { IconButton, Modal, ModalCloseButton, ModalContent, ModalOverlay, SimpleGrid } from "@chakra-ui/react";

interface Props {
    show: boolean;
    close: () => void;
    palette: string[];
    color?: string | null | undefined;
    onColorChanged: (clr: string) => void;
}

const ColorPaletteDialog: FunctionComponent<Props> = ({ show, close, palette, color, onColorChanged }: Props) => {
    const [selectedColor, setSelectedColor] = useState<string>(color || "brandSecondary.purple");

    const handleSelectColor = (clr: string) => {
        setSelectedColor(clr);
        onColorChanged(clr);
        close();
    };

    return (
        <Modal isOpen={show} onClose={close} size="xs">
            <ModalCloseButton />
            <ModalOverlay />
            <ModalContent>
                <SimpleGrid columns={4} p="1rem" gap=".5rem">
                    {palette.map(color => (
                        <IconButton
                            key={color}
                            aria-label={color}
                            onClick={() => handleSelectColor(color)}
                            variant={selectedColor === color ? "outline" : "outline"}
                            sx={{ backgroundColor: color, height: 14, width: 16, borderWidth: selectedColor === color ? 6 : 0 }}
                        />
                    ))}
                </SimpleGrid>
            </ModalContent>
        </Modal>
    );
};

export default ColorPaletteDialog;

export enum EngagementContactType {
    ManagingDirector = 1,
    LeadDirector = 2,
    EngagementLead = 3,
    EngagementContributor = 4,
}

export enum NavDestination {
    Iframe = 1,
    Page = 2,
}

export enum NavArea {
    Engagement = 1,
    Top = 2,
    Bottom = 3,
}

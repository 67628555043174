import { Button } from "@chakra-ui/react";
import { FileContainer } from "am-tax-fe-core";
import { FunctionComponent } from "react";
import { map, mapList } from "./upload-btn-utils";

interface Props {
    title: string;
    accept?: string;
    multiple?: boolean;
    onFileSelectionChanged: (files: FileContainer[] | FileContainer | undefined) => void;
    variant?: "solid" | "outline" | "ghost" | "link" | undefined;
    isMenuLink?: boolean | undefined;
}

const UploadButton: FunctionComponent<Props> = ({ title, accept = "*", multiple = false, onFileSelectionChanged, variant = "solid", isMenuLink = false }) => {
    const handleUploadBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const parent = e.currentTarget.parentNode as HTMLButtonElement;
        if (parent) {
            parent?.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) {
            onFileSelectionChanged(undefined);
        } else if (!multiple) {
            onFileSelectionChanged(map(files[0], 0));
        } else {
            onFileSelectionChanged(mapList(files));
        }
    };

    return (
        <>
            <input type="file" id="actual-btn" onChange={handleFileChange} accept={accept} multiple={multiple} hidden />
            <label htmlFor="actual-btn">
                <Button
                    onClick={handleUploadBtnClick}
                    variant={variant}
                    sx={{ fontWeight: isMenuLink ? "normal" : "inherit", color: isMenuLink ? "blackAlpha.800" : "inherit" }}
                >
                    {title}
                </Button>
            </label>
        </>
    );
};

export default UploadButton;

import { FunctionComponent, useEffect, useState } from "react";
import { Page } from "../../Page.tsx";
import { PageLoading } from "am-tax-fe-core";
import { useEngagementReports, useProjectReports, useReportAuth } from "../../../api/apiQueries.ts";
import { ReportAuthToken } from "../../../api/api.ts";
import { useParams } from "react-router-dom";
import { Alert, AlertIcon, Button } from "@chakra-ui/react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { Global, css } from "@emotion/react";
import { topNavHeightAtom } from "am-tax-fe-core";
import { useAtomValue } from "jotai";

export const BaseReportPage: FunctionComponent = () => {
    const { engagementId, projectId, reportId } = useParams();
    const powerBIPanelHeight = useAtomValue(topNavHeightAtom);

    const [reportAuthToken, setReportAuthToken] = useState<ReportAuthToken | undefined>();
    const [powerBiParams, setPowerBiParams] = useState<{ groupId?: string; reportId?: string; formatError?: boolean }>({});

    const { data: engagementReports, isLoading: engagementReportsLoading } = useEngagementReports(engagementId!, !projectId);
    const { data: projectReports, isLoading: projectReportsLoading } = useProjectReports(engagementId!, projectId!);
    const { mutate: authorizeReport, isPending: authLoading } = useReportAuth();
    const reports = projectId ? projectReports : engagementReports;
    const reportsLoading = projectId ? projectReportsLoading : engagementReportsLoading;

    const currentReport = reports?.find(x => x.id === reportId);

    useEffect(() => {
        if (!currentReport?.url) {
            return;
        }
        try {
            const arr = currentReport.url.toLowerCase().split("/");
            const groupIndex = arr.indexOf("groups");
            const reportIndex = arr.indexOf("reports");
            if (groupIndex === -1 || reportIndex === -1) {
                throw "Route params do not exist.";
            }
            const powerBiGroupId = arr[groupIndex + 1];
            const powerBiReportId = arr[reportIndex + 1];
            if (!powerBiGroupId || !powerBiReportId) {
                throw "Route params values are empty.";
            }
            setPowerBiParams({ reportId: powerBiReportId, groupId: powerBiGroupId, formatError: !powerBiReportId || !powerBiGroupId });
        } catch (error) {
            setPowerBiParams({ formatError: true });
        }
    }, [currentReport]);

    useEffect(() => {
        if (!powerBiParams.groupId || !powerBiParams.reportId) {
            return;
        }
        authorizeReport(
            { reportId: powerBiParams.reportId, groupId: powerBiParams.groupId, engagementId: engagementId || "", projectId },
            {
                onSuccess: data => {
                    setReportAuthToken(data);
                },
            },
        );
    }, [powerBiParams, authorizeReport, engagementId, projectId]);

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <Page
            title="Report"
            gutter={
                <Button variant="outline" onClick={() => handleGoBack()}>
                    Back to Reports
                </Button>
            }
        >
            {powerBiParams.formatError && (
                <Alert my="1rem" status="warning">
                    <AlertIcon />
                    The report URL is not a valid URL for Power BI Reports.
                </Alert>
            )}

            {(reportsLoading || authLoading) && <PageLoading text={reportsLoading ? "Loading reports.." : "Authorizing.."} />}

            {reportAuthToken?.embedUrl && (
                <>
                    <Global
                        styles={css`
                            .report-container {
                                height: calc(100vh - ${powerBIPanelHeight}px);
                            }
                        `}
                    />
                    <PowerBIEmbed
                        embedConfig={{
                            type: "report", // Supported types: report, dashboard, tile, visual, qna and paginated report
                            viewMode: models.ViewMode.View,
                            id: reportAuthToken.reportId || "",
                            embedUrl: reportAuthToken.embedUrl,
                            accessToken: reportAuthToken.accessToken || "",
                            //accessToken: configValue || "",
                            //accessToken: undefined,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                layoutType: models.LayoutType.Master,
                                background: models.BackgroundType.Transparent,
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: true,
                                    },
                                },
                                navContentPaneEnabled: false,
                            },
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    "loaded",
                                    function () {
                                        return;
                                    },
                                ],
                                [
                                    "rendered",
                                    function () {
                                        return;
                                    },
                                ],
                                [
                                    "error",
                                    function (event) {
                                        console.log(event?.detail);
                                    },
                                ],
                            ])
                        }
                        cssClassName={"report-container"}
                    />
                </>
            )}
        </Page>
    );
};

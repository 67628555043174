import { FunctionComponent } from "react";

interface Props {
    isReadOnly: boolean;
    readOnlyValue: React.ReactNode;
    children: React.ReactNode;
}

const RenderReadOnly: FunctionComponent<Props> = ({ isReadOnly, readOnlyValue, children }) => {
    return isReadOnly ? readOnlyValue : children;
};

export default RenderReadOnly;

import { FunctionComponent, useRef, useState } from "react";
import { Page } from "../Page.tsx";
import { AbsoluteCenter, Box, Card, CardBody, CardHeader, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select } from "@chakra-ui/react";
import { Button, FileContainer, Textarea, useToast } from "am-tax-fe-core";
import { SubmitHandler, useForm } from "react-hook-form";
import { COUNTRY_CODES } from "../../constants/index.ts";
import { FeedbackArgs, FeedbackResponse, UploadDocumentsToFeedbackArgs } from "../../api/api.ts";
import { useCreateFeedback, useUploadDocumentToFeedback } from "../../api/apiQueries.ts";
import { displayUrgency, UrgencyOptions } from "../../enums/FeedbackUrgency.ts";
import { displayFeedbackType, FeedbackTypeOptions } from "../../enums/FeedbackType.ts";
import { UploadPanel } from "../../components/DocumentListPanel/UploadPanel.tsx";
import { useNavigate } from "react-router-dom";

export const FeedbackPage: FunctionComponent = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const formValues = {
        engagementName: "",
        projectName: "",
        description: "",
        feedbackType: undefined,
        urgency: undefined,
        countryNumber: "",
        contactNumber: "",
    };

    const [uploading, setUploading] = useState(false);
    const [fileQueue, setFileQueue] = useState<FileContainer[]>([]);

    const uploadFileToFeedbackQuery = useUploadDocumentToFeedback();

    const formRef = useRef<HTMLFormElement>(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        values: formValues,
    });

    const createFeedbackQuery = useCreateFeedback();
    const createFeedback = async (feedback: FeedbackArgs) => {
        const response = await createFeedbackQuery.mutateAsync(feedback);
        toast({
            title: "Successful.",
            description: "Successfully submitted feedback.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });

        return response;
    };
    const onSubmit: SubmitHandler<typeof formValues> = async data => {
        const args: FeedbackArgs = {
            engagementName: data.engagementName || "",
            description: data.description,
            projectName: data.projectName || "",
            contactNumber: `${data.countryNumber}-${data.contactNumber}`,
            feedbackType: data.feedbackType,
            urgency: data.urgency,
        };
        const feedback: FeedbackResponse = await createFeedback(args);

        if (feedback.id) {
            if (fileQueue.length > 0) {
                fileQueue.forEach(file => {
                    setUploading(true);
                    uploadFile(feedback.id!, file);
                });
            }
        }

        navigate("/myEngagements");
    };
    const uploadFile = (feedbackId: string, fileContainer: FileContainer) => {
        const args: UploadDocumentsToFeedbackArgs = { feedbackId, fileContainer };
        uploadFileToFeedbackQuery.mutateAsync(args);
    };

    const onUploadComplete = async () => {
        try {
            setFileQueue([]);
            toast({
                title: "Saved",
                description: "Saved Feedback and Uploaded Files.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setUploading(false);
        }
    };
    return (
        <Page title="Feedback">
            <Flex direction={"column"} gap={"3rem"}>
                <Card>
                    <CardHeader bgGradient="linear(to-br, blue.900, blue.700)"></CardHeader>
                    <CardBody>
                        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                            <Flex gap="2rem" mb={"1rem"} width={"100%"} alignItems={"stretch"}>
                                <FormControl isInvalid={!!errors?.engagementName}>
                                    <FormLabel>Engagement/Project Name:</FormLabel>
                                    <Input {...register("engagementName")} />
                                    <FormErrorMessage>{errors?.engagementName?.message}</FormErrorMessage>
                                </FormControl>
                                {/*<FormControl isInvalid={!!errors?.projectName}>*/}
                                {/*    <FormLabel>Project Name:</FormLabel>*/}
                                {/*    <Input {...register("projectName")} />*/}
                                {/*    <FormErrorMessage>{errors?.projectName?.message}</FormErrorMessage>*/}
                                {/*</FormControl>*/}
                            </Flex>
                            <Flex gap="2rem" mb={"1rem"} width={"100%"} alignItems={"stretch"}>
                                <FormControl isInvalid={!!errors?.description}>
                                    <FormLabel>Problem(/Feedback) Description:</FormLabel>
                                    <Textarea {...register("description", { required: "Please provide feedback description" })} />
                                    <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex gap="2rem" width={"100%"} mb={"1rem"} alignItems={"stretch"}>
                                <FormControl isInvalid={!!errors?.feedbackType}>
                                    <FormLabel>Feedback Type:</FormLabel>
                                    <Select {...register("feedbackType", { required: "Please provide feedback type" })} placeholder={"Select feedback type"}>
                                        {FeedbackTypeOptions.map(value => (
                                            <option key={value} value={value}>
                                                {displayFeedbackType(value)}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{errors?.feedbackType?.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors?.urgency}>
                                    <FormLabel>Urgency:</FormLabel>
                                    <Select placeholder={"Select urgency"} {...register("urgency", { required: "Please provide urgency" })}>
                                        {UrgencyOptions.map(value => (
                                            <option key={value} value={value}>
                                                {displayUrgency(value)}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{errors?.urgency?.message}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex width={"50%"}>
                                <FormControl isInvalid={!!errors?.contactNumber}>
                                    <FormLabel>Contact Number:</FormLabel>
                                    <Flex>
                                        <Select placeholder={"Country code"} {...register("countryNumber")} width={"50%"} mr={"5px"}>
                                            {COUNTRY_CODES.map(value => (
                                                <option key={value.code} value={value.code}>
                                                    {value.country} {value.code}
                                                </option>
                                            ))}
                                        </Select>
                                        <Input {...register("contactNumber")} />
                                    </Flex>
                                    <FormErrorMessage>{errors?.contactNumber?.message}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                                <Button type="submit" isLoading={uploading}>
                                    Submit
                                </Button>
                            </Flex>
                        </form>

                        <Box sx={{ position: "relative", py: 10 }}>
                            <Divider />
                            <AbsoluteCenter bg="white" px="4">
                                Attachments
                            </AbsoluteCenter>
                        </Box>
                        <UploadPanel
                            immediateUpload={false}
                            fileQueue={fileQueue}
                            setFileQueue={setFileQueue}
                            onUploadComplete={onUploadComplete}
                            uploadFile={function (q: FileContainer): void {}}
                        />
                    </CardBody>
                </Card>
            </Flex>
        </Page>
    );
};

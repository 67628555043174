import { AreaPermissions, PermissionsByAreaType, SecurityArea } from "../../auth";

export function canCreateOrUpdateOrReassign<A extends SecurityArea>(
    areaPermissions: AreaPermissions<A>,
    createPermission: PermissionsByAreaType<A>,
    updatePermission: PermissionsByAreaType<A>,
    reassignedPermission: PermissionsByAreaType<A>,
    entityId: string | undefined,
): boolean {
    if (entityId) {
        return areaPermissions.hasAny(updatePermission, reassignedPermission);
    } else {
        return areaPermissions.has(createPermission);
    }
}

export function readonlyAccess<A extends SecurityArea>(
    areaPermissions: AreaPermissions<A>,
    updatePermission: PermissionsByAreaType<A> | null,
    reassignedPermission: PermissionsByAreaType<A> | null,
    readPermission: PermissionsByAreaType<A> | null,
    entityId: string | undefined,
): boolean {
    if (entityId) {
        return !areaPermissions.has(updatePermission) && (areaPermissions.has(reassignedPermission) || areaPermissions.has(readPermission));
    } else {
        return false;
    }
}

import React, { useEffect, useLayoutEffect, useRef, useMemo, useState, useImperativeHandle } from "react";
import sanitizeHtml from "sanitize-html";
import { Box, Text, useFormControlContext } from "@chakra-ui/react";
import Quill from "quill";
import "quill-mention";

import { getMentionModule, getStandardToolbar } from "./utils";
import { Participant } from "../../../api";
import { UseFormRegisterReturn } from "react-hook-form";

import "quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";

export interface Props {
    defaultValue?: string; // uncontrolled
    onContentChange: (html: string, text: string) => void;
    mentions?: Participant[];
    showToolbar?: boolean;
    maxLength?: number;
    showRemaining?: boolean;
    placeholder?: string;
    register?: UseFormRegisterReturn;
}

export interface RichTextEditorRef {
    reset: () => void;
}

const RichTextEditor = React.forwardRef<RichTextEditorRef, React.PropsWithChildren<Props>>((props, ref) => {
    const { defaultValue, onContentChange, mentions, showToolbar = true, showRemaining = !!props.maxLength, maxLength, placeholder, register } = props;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill | null>(null);
    const handleContentChangeRef = useRef<(() => void) | null>(null);
    const [length, setLength] = useState(0);
    const formControlContext = useFormControlContext();
    const { isInvalid } = useMemo(() => formControlContext || {}, [formControlContext]);

    useImperativeHandle(ref, () => ({
        reset: () => {
            quillRef.current?.setText("");
        },
    }));

    const modules = useMemo(() => {
        const list: { [moduleName: string]: object | boolean } = {};
        list.toolbar = showToolbar ? getStandardToolbar() : false;
        list.mention = mentions ? getMentionModule(mentions) : false;
        return list;
    }, [showToolbar, mentions]);

    const handleContentChange = () => {
        const editor = quillRef.current;
        if (editor) {
            const editorLength = editor.getLength() - 1;
            if (maxLength && editorLength > maxLength) {
                editor.deleteText(maxLength, editorLength);
            }
            const plainText = editor.getText();
            onContentChange(editor.root.innerHTML, plainText);
            setLength(editorLength);
        }
    };

    useLayoutEffect(() => {
        handleContentChangeRef.current = handleContentChange;
    });

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));
            const quill = new Quill(editorContainer, {
                theme: "snow",
                modules,
                placeholder,
            });
            quillRef.current = quill;

            if (defaultValue) {
                quill.root.innerHTML = sanitizeHtml(defaultValue);
            }

            quill.on(Quill.events.TEXT_CHANGE, () => {
                handleContentChangeRef.current?.();
            });
        }

        return () => {
            quillRef.current = null;
            if (container) {
                container.innerHTML = "";
            }
        };
    }, [placeholder, defaultValue, modules]);

    const sxStyles = {
        position: "relative",
        ".ql-toolbar": {
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            borderColor: isInvalid ? "cherry.400" : "#ccc",
            borderWidth: isInvalid ? "2px" : "1px",
            borderBottom: "1px solid #ccc",
        },
        ".ql-container": {
            fontSize: "1rem",
            borderTopLeftRadius: showToolbar ? "0" : "6px",
            borderTopRightRadius: showToolbar ? "0" : "6px",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            borderColor: isInvalid ? "cherry.400" : "#ccc",
            borderWidth: isInvalid ? "2px" : "1px",
        },
    };

    return (
        <Box sx={sxStyles}>
            {register && <input {...register} style={{ display: "none" }} aria-hidden="true" />}
            <div ref={containerRef} />
            {showRemaining && !!maxLength && (
                <Text
                    position={"absolute"}
                    zIndex={1}
                    variant={"info"}
                    bg={"whiteAlpha.800"}
                    lineHeight={"1.25rem"}
                    color={length >= maxLength ? "red.500" : undefined}
                    bottom={"2px"}
                    right={"1.25rem"}
                    px={"2px"}
                    m={0}
                    borderRadius={"4px"}
                >
                    {!!length && `${length < maxLength ? length : maxLength}/${maxLength}`}
                </Text>
            )}
        </Box>
    );
});

export default RichTextEditor;

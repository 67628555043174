import { FunctionComponent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, Heading, Icon, StackProps, Tooltip, VStack } from "@chakra-ui/react";

import { AgressoEngagement, AgressoProject, Engagement, Project, useAgressoProjects, useCurrentUser, useProjects, useRequestAccessToProject } from "../../api";
import { Filter, Shimmer, useToast } from "am-tax-fe-core";
import { ProjectIcon } from "../../util/engagementUtils.ts";
import { useAppPermissions } from "../../hooks";
import { ProjectArea } from "../../auth";
import { AccessStatus } from "../../enums/AccessStatus.ts";
import { IconHomeDot, IconHomeOff, IconHomeQuestion } from "@tabler/icons-react";

export type EngagementsListProps = {
    engagement?: Engagement | AgressoEngagement;
    showFilter?: boolean;
} & StackProps;

export const ProjectList: FunctionComponent<EngagementsListProps> = ({ engagement, showFilter = true, ...stackProps }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data;
    const isEngagementProvisioned = !!(engagement as Engagement)?.engagementId;
    const projectsQuery = useProjects((engagement as Engagement)?.engagementId, isEngagementProvisioned);
    const agressoProjectsQuery = useAgressoProjects((engagement as AgressoProject)?.projectCode, !isEngagementProvisioned);
    const appPermissions = useAppPermissions();
    const requestAccessToProjectQuery = useRequestAccessToProject();

    const data = projectsQuery.data || agressoProjectsQuery.data;

    const { projects, agressoProjects } = useMemo(
        () => (data ? { projects: data.projects ?? [], agressoProjects: data.agressoProjects ?? [] } : { projects: [], agressoProjects: [] }),
        [data],
    );

    const [filter, setFilter] = useState("");
    function getFilterString(project: Project | AgressoProject) {
        return project.name + project.projectCode;
    }
    const { provisioned, unprovisioned } = useMemo(() => {
        return {
            provisioned: filter.length ? projects.filter(project => getFilterString(project).toLowerCase().includes(filter.toLowerCase())) : projects,
            unprovisioned: filter.length
                ? agressoProjects.filter(project => getFilterString(project).toLowerCase().includes(filter.toLowerCase()))
                : agressoProjects,
        };
    }, [projects, agressoProjects, filter]);
    function updateFilter(value: string) {
        setFilter(value);
    }

    const goToProject = (project: Project) => {
        navigate(`/engagement/${project.engagementId}/project/${project.projectId}/dashboard`);
    };

    const provisionProject = (project: AgressoProject) => {
        navigate(`client/${engagement!.clientId}/provisionProject/${project.engagementId}/${project.projectCode}/${encodeURIComponent(project.name)}`);
    };

    const requestAccessToProject = async (project: Project) => {
        await requestAccessToProjectQuery.mutateAsync({ projectId: project.projectId, engagementId: project.engagementId });
        toast({
            title: "Access Requested",
            description: `Access to ${project.projectCode} has been requested`,
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    const provisionCustomProject = () => {
        navigate(`client/${engagement!.clientId}/provisionCustomProject/${(engagement as Engagement)!.engagementId}`);
    };

    return (
        <VStack padding="2rem" alignItems="stretch" height="100%" spacing={"1rem"} {...stackProps}>
            {showFilter && (
                <Box>
                    <Filter
                        placeholder="Filter Projects"
                        onChange={updateFilter}
                        count={{ total: projects.length, shown: provisioned.length + unprovisioned.length }}
                    />
                </Box>
            )}

            {((isEngagementProvisioned && projectsQuery.isLoading) || (!isEngagementProvisioned && agressoProjectsQuery.isLoading)) && (
                <VStack
                    spacing="1px"
                    alignItems="stretch"
                    overflowY="auto"
                    divider={
                        <Box border={0}>
                            <Divider borderColor="blue.100" />
                        </Box>
                    }
                >
                    {Array(2)
                        .fill(null)
                        .map((val, i) => (
                            <Shimmer key={i} height="40px" mode={"light"} />
                        ))}
                </VStack>
            )}

            {provisioned.length > 0 && (
                <VStack
                    spacing="1px"
                    alignItems="stretch"
                    overflowY="auto"
                    divider={
                        <Box border={0}>
                            <Divider borderColor="blue.100" />
                        </Box>
                    }
                >
                    {provisioned.map(project => (
                        <Button
                            key={project.projectCode}
                            rightIcon={
                                <Icon
                                    as={
                                        project.accessStatus === AccessStatus.Denied
                                            ? IconHomeOff
                                            : project.accessStatus === AccessStatus.Requested
                                              ? IconHomeDot
                                              : project.accessStatus === AccessStatus.NotRequested
                                                ? IconHomeQuestion
                                                : ProjectIcon
                                    }
                                    boxSize={"1.5rem"}
                                />
                            }
                            isDisabled={project.accessStatus === AccessStatus.Denied || project.accessStatus === AccessStatus.Requested}
                            color={"blue.900"}
                            bg={"transparent"}
                            _hover={{ bg: "blueAlpha.100" }}
                            borderRadius={0}
                            onClick={() => {
                                if ((project as Project).accessStatus === AccessStatus.NotRequested) {
                                    requestAccessToProject(project as Project);
                                } else if ((project as Project).accessStatus === AccessStatus.Accepted) {
                                    goToProject(project as Project);
                                }
                            }}
                            fontWeight={"normal"}
                        >
                            <Box overflow={"hidden"} textOverflow={"ellipsis"}>
                                {project.projectCode} - {project.name}
                            </Box>
                            <Box flexGrow={1} textAlign={"end"} pl={".5rem"}>
                                {project.accessStatus === AccessStatus.Requested
                                    ? "Access Requested"
                                    : project.accessStatus === AccessStatus.NotRequested
                                      ? "Request Access"
                                      : ""}
                            </Box>
                        </Button>
                    ))}
                </VStack>
            )}

            {currentUser?.isInternal && appPermissions.has(ProjectArea.create) && unprovisioned.length > 0 && (
                <>
                    <Heading variant={"info"} alignSelf={"center"} pt={"1rem"}>
                        Unprovisioned Projects
                    </Heading>

                    <VStack
                        spacing="1px"
                        alignItems="stretch"
                        overflowY="auto"
                        divider={
                            <Box border={0}>
                                <Divider borderColor="blue.100" />
                            </Box>
                        }
                    >
                        {unprovisioned.map(project => (
                            <Tooltip key={project.projectCode} label="Engagement not yet provisioned" isDisabled={isEngagementProvisioned}>
                                <Button
                                    rightIcon={<Icon as={ProjectIcon} boxSize={"1.5rem"} />}
                                    justifyContent="space-between"
                                    color={"gray.400"}
                                    bg={"transparent"}
                                    _hover={{ bg: "blueAlpha.100" }}
                                    borderRadius={0}
                                    aria-label={`provision project`}
                                    onClick={() => provisionProject(project as AgressoProject)}
                                    isDisabled={!isEngagementProvisioned}
                                    fontWeight={"normal"}
                                >
                                    <Box overflow={"hidden"} textOverflow={"ellipsis"}>
                                        {project.projectCode} - {project.name}
                                    </Box>
                                </Button>
                            </Tooltip>
                        ))}
                    </VStack>
                </>
            )}

            {currentUser?.isInternal && appPermissions.has(ProjectArea.create) && engagement && (
                <Tooltip label="Engagement not yet provisioned" isDisabled={isEngagementProvisioned}>
                    <Button
                        mt={"1rem"}
                        color={"blue.900"}
                        _hover={{ bg: "blueAlpha.100" }}
                        borderColor="blue.100"
                        variant={"outline"}
                        borderRadius={0}
                        onClick={() => {
                            provisionCustomProject();
                        }}
                        isDisabled={!isEngagementProvisioned}
                        fontWeight={"normal"}
                    >
                        Create Custom Project
                    </Button>
                </Tooltip>
            )}
        </VStack>
    );
};

import { FunctionComponent } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { ProvisionCustomEngagementForm } from "./ProvisionCustomEngagementForm.tsx";
import { ClientSearchResponse } from "../../api/api.ts";

interface Props {
    selected?: ClientSearchResponse;
    show: boolean;
    onClose: () => void;
}
export const ProvisionCustomEngagementModal: FunctionComponent<Props> = ({ selected, show, onClose }: Props) => {
    return (
        <Modal isOpen={show} onClose={onClose} size={"xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Setup New Engagement</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={"1rem"}>Please provide some additional information.</Text>

                    <ProvisionCustomEngagementForm client={selected} onCancel={onClose} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

import { UseMutationResult } from "@tanstack/react-query";

export class Mutation<T, D = unknown> {
    constructor(query: UseMutationResult<D, unknown, T>) {
        this.query = query;
    }
    public query: UseMutationResult<D, unknown, T>;
    public updateArgs(args: T): T {
        return args;
    }
}

export class SearchFunction<T, R> {
    constructor(fn: (t: T) => Promise<R>) {
        this.fn = fn;
    }
    private fn: (t: T) => Promise<R>;
    public search(t: T): Promise<R> {
        return this.fn(this.updateArgs(t));
    }
    public updateArgs(args: T): T {
        return args;
    }
}

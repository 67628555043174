import { FunctionComponent, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Avatar, getAccount } from "am-tax-fe-core";
import { Center, Link, VStack, Text, Box } from "@chakra-ui/react";

import { Page } from "../Page.tsx";
import { useCurrentUser } from "../../api";
import { AccountForm } from "./AccountForm.tsx";

export const MyAccountPage: FunctionComponent = () => {
    const userQuery = useCurrentUser();

    const { instance } = useMsal();

    const account = getAccount(instance);

    const name = useMemo(() => {
        const name = account?.name || account?.idTokenClaims?.givenname + " " + account?.idTokenClaims?.surname;
        if (name.trim()) {
            return name;
        }
    }, [account]);

    return (
        <Page title="My Account">
            <Center>
                <VStack spacing={"1.5rem"}>
                    <Avatar name={name} size={"xl"} />
                    <Box textAlign={"center"}>
                        <Text fontSize={"xl"}>
                            {userQuery.data?.firstName} {userQuery.data?.lastName}
                        </Text>
                        <Text>{userQuery.data?.email}</Text>
                    </Box>

                    {userQuery.data && <AccountForm user={userQuery.data} />}

                    <Link sx={{ mt: "3rem" }} as={NavLink} to={"/manageMyData"} textDecoration={"underline"}>
                        Manage My Data
                    </Link>
                </VStack>
            </Center>
        </Page>
    );
};

import { FunctionComponent } from "react";
import { Flex, Box, Text, IconButton, Tooltip } from "@chakra-ui/react";
import { IconTrash, IconEye, IconEyeOff } from "@tabler/icons-react";
import { getInitials, getRandomThemeColorFromName, useConfirm } from "am-tax-fe-core";
import { formatTimestampStr } from "am-tax-fe-core";

import { CommentResponse, DeleteRequestCommentArgs, PublishRequestCommentArgs, User } from "../../api";
import { useCurrentUser, useDeleteRequestComment, useTogglePublishRequestComment } from "../../api/apiQueries";
import TalkBubble from "./TalkBubble";
import RichTextLabel from "./rich-text/RichTextLabel";

interface Props {
    requestId: string;
    engagementId: string;
    projectId?: string;
    comment: CommentResponse;
    isConversationPublished: boolean | undefined;
}

const CommentItem: FunctionComponent<Props> = ({ requestId, engagementId, projectId, comment, isConversationPublished }: Props) => {
    const { confirm: confirmDelete, ConfirmDialog: ConfirmDeleteDialog } = useConfirm({
        title: "Delete comment",
        prompt: "Are you sure you want to delete this comment?",
    });

    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data as User;

    const avatarName = `${comment.user?.firstName} ${comment.user?.lastName}`;
    const avatarColor = getRandomThemeColorFromName(avatarName);

    let timeDifference: string | undefined;
    if (comment.dateCreated) {
        timeDifference = formatTimestampStr(comment.dateCreated, { showRelativeDay: true });
    }

    const deleteCommentQuery = useDeleteRequestComment();
    const deleteComment = async () => {
        const args: DeleteRequestCommentArgs = {
            requestId,
            commentId: comment.id ?? "",
            engagementId,
            projectId,
        };
        await deleteCommentQuery.mutateAsync(args);
    };

    const handleDelete = async () => {
        if (await confirmDelete()) {
            deleteComment();
        }
    };

    const toggleConversationPublishQuery = useTogglePublishRequestComment();
    const handleTogglePublish = async () => {
        const args: PublishRequestCommentArgs = {
            requestId,
            commentId: comment.id ?? "",
            engagementId,
            projectId,
            publish: !comment.isPublished,
        };
        await toggleConversationPublishQuery.mutateAsync(args);
    };

    return (
        <>
            <Box mb="1rem">
                <Flex align="center" gap=".5rem">
                    <Flex bg={avatarColor} borderRadius={"full"} boxSize="2rem" minW="2rem" align={"center"} justify={"center"} color={"whiteAlpha.900"}>
                        <Tooltip label={avatarName}>
                            <Box fontSize={".75rem"} fontWeight={"500"}>
                                {getInitials(avatarName)}
                            </Box>
                        </Tooltip>
                    </Flex>
                    <Box>
                        <TalkBubble
                            bg={isConversationPublished && comment.isPublished ? "yellowAlpha.200" : "blackAlpha.50"}
                            textColor={comment.isPublished ? "inherit" : "blackAlpha.600"}
                        >
                            {comment.content && <RichTextLabel html={comment.content} />}
                        </TalkBubble>
                    </Box>
                </Flex>
                <Flex mt=".25rem" pl="2.5rem" align="center">
                    <Text ml=".5rem" variant="info">
                        {timeDifference}
                    </Text>
                    {currentUser.isInternal && (
                        <Tooltip label={comment.isPublished ? "Hide comment from clients" : "Hidden from clients"}>
                            <IconButton
                                aria-label="Hide comment from clients"
                                variant="naked"
                                ml=".25rem"
                                color="gray.500"
                                icon={comment.isPublished ? <IconEye size={18} /> : <IconEyeOff size={18} />}
                                onClick={handleTogglePublish}
                                isLoading={deleteCommentQuery.isPending}
                            />
                        </Tooltip>
                    )}
                    {currentUser.id === comment.user?.id && (
                        <Tooltip label="Delete comment">
                            <IconButton
                                aria-label="Delete comment"
                                variant="naked"
                                ml=".25rem"
                                color="gray.500"
                                icon={<IconTrash size={18} />}
                                onClick={handleDelete}
                                isLoading={deleteCommentQuery.isPending}
                            />
                        </Tooltip>
                    )}
                </Flex>
            </Box>
            <ConfirmDeleteDialog />
        </>
    );
};

export default CommentItem;

import { FunctionComponent } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

export interface Props extends BoxProps {
    children: React.ReactNode;
}

const TalkBubble: FunctionComponent<Props> = (props: Props) => {
    const afterStyle = {
        content: "''",
        position: "absolute",
        left: "0",
        top: "50%",
        width: "0",
        height: "0",
        border: "8px solid transparent",
        borderRightColor: props.bg,
        borderLeft: "0",
        marginTop: "-8px",
        marginLeft: "-8px",
    };

    return (
        <Box ml=".5rem" position="relative">
            <Box {...props} borderRadius=".4em">
                <Box _after={afterStyle}>
                    {props.children}
                </Box>
            </Box>
        </Box>
    );
};

export default TalkBubble;

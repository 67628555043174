import { FunctionComponent, useState } from "react";
import { Box, Text, IconButton, Flex, Heading, Tooltip, LinkBox, LinkOverlay, Divider, AbsoluteCenter, SimpleGrid, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Application, useApplications, useEnableApplication, useUpdateApplication } from "../../api/index.ts";
import { IconPalette, IconChevronUp, IconChevronDown, IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";
import { Page } from "../Page.tsx";

import { PageLoading } from "am-tax-fe-core";
import React from "react";
import ColorPaletteDialog from "./ColorPaletteDialog.tsx";

export type Props = {
    engagementId: string;
    projectId?: string;
};

export const BaseApplicationsPage: FunctionComponent<Props> = ({ engagementId, projectId }) => {
    const { mutate: enableApplication, isPending: enableAppIsPending } = useEnableApplication();
    const { mutate: updateApplication } = useUpdateApplication();

    const { data, isLoading: isLoadingApplications } = useApplications(engagementId, projectId);
    const [selectedApplication, setSelectedApplication] = useState<Application | undefined>();
    const [selectedEmbedApplication, setSelectedEmbedApplication] = useState<Application | undefined>();

    const colorPalette = [
        "brandPrimary.lightBlue",
        "brandPrimary.brightBlue",
        "brandSecondary.aqua",
        "brandSecondary.red",
        "brandSecondary.orange",
        "brandSecondary.yellow",
        "brandSecondary.purple",
        "brandSecondary.green",
    ];
    const defaultAppColor = colorPalette[colorPalette.length - 1];
    const [showColorPaletteDialog, setShowColorPaletteDialog] = useState(false);
    const closeColorPaletteDialog = () => setShowColorPaletteDialog(false);

    const handleMoveUp = (app: Application) => {
        const args = {
            ...app,
            engagementId,
            projectId,
            sortOrder: (app.sortOrder ?? 0) + 1,
        };
        updateApplication(args);
    };

    const handleMoveDown = (app: Application) => {
        const args = {
            ...app,
            engagementId,
            projectId,
            sortOrder: (app.sortOrder ?? 0) - 1,
        };
        updateApplication(args);
    };

    const handleColorPaletteBtnClicked = (app: Application) => {
        setSelectedApplication(app);
        setShowColorPaletteDialog(true);
    };

    const handleColorChanged = (color: string) => {
        const app = selectedApplication as Application;
        const args = {
            ...app,
            engagementId,
            projectId,
            appColor: color,
        };
        updateApplication(args);
    };

    const handleDeactivate = (app: Application) => {
        const args = {
            ...app,
            engagementId,
            projectId,
            enabled: false,
        };
        updateApplication(args);
    };

    const handleActivate = (app: Application) => {
        setSelectedApplication(app);
        const args = {
            ...app,
            engagementId,
            projectId,
        };
        enableApplication(args);
    };

    const handleApplicationClick = (e: React.MouseEvent<HTMLElement>, app: Application) => {
        if (app.linkAction === 0) {
            window.open(app.url);
        } else {
            setSelectedEmbedApplication(app);
        }
        e.preventDefault();
    };

    const showSortOrder = false;
    const getSortButtons = (app: Application) => {
        return (
            <>
                <Tooltip label="Move up">
                    <IconButton aria-label="Move Up" variant="outline" size="sm" icon={<IconChevronUp size={20} />} onClick={() => handleMoveUp(app)} />
                </Tooltip>
                <Tooltip label="Move down">
                    <IconButton
                        color="primary"
                        aria-label="Move Down"
                        variant="outline"
                        size="sm"
                        icon={<IconChevronDown size={20} />}
                        onClick={() => handleMoveDown(app)}
                    />
                </Tooltip>
            </>
        );
    };

    const getToolbar = (app: Application) => (
        <Flex p="0.25rem" gap=".25rem" justifyContent="space-between" sx={{ backgroundColor: "white", borderBottom: "1px solid #c4c4c4" }}>
            <Flex gap=".25rem">
                {showSortOrder && getSortButtons(app)}
                <Tooltip label="Change color">
                    <IconButton
                        aria-label="Color Palette"
                        variant="outline"
                        size="sm"
                        icon={<IconPalette size={20} />}
                        onClick={() => handleColorPaletteBtnClicked(app)}
                    />
                </Tooltip>
            </Flex>
            <Tooltip label="Deactive">
                <IconButton
                    aria-label="Deactivate Application"
                    variant="outline"
                    size="sm"
                    icon={<IconCircleMinus size={20} />}
                    onClick={() => handleDeactivate(app)}
                />
            </Tooltip>
        </Flex>
    );

    const enabledApplications = data
        ?.filter(x => x.enabled)
        .sort((a, b) => {
            if (a.sortOrder === undefined || b.sortOrder === undefined) return 0;
            else return a.sortOrder < b.sortOrder ? 1 : -1;
        })
        .map(app => {
            return (
                <LinkBox
                    as="article"
                    key={app.applicationTemplateId}
                    width="100%"
                    borderWidth="1px"
                    boxShadow="3px 3px 3px 0px rgba(0,0,0,0.1)"
                    borderRadius="5px"
                >
                    <LinkOverlay as={Link} to={app.url} onClick={e => handleApplicationClick(e, app)} />
                    <Box sx={{ height: "10px", backgroundColor: app.appColor || defaultAppColor, borderTopRadius: "5px" }}></Box>
                    <Box as="a">{getToolbar(app)}</Box>

                    <Box m="1rem">
                        <Heading mb="1rem" fontSize="lg" fontWeight="500" width="100%">
                            {app.name}
                        </Heading>
                        <Box overflowX="clip">{app.description}</Box>
                    </Box>
                </LinkBox>
            );
        });

    const appTemplates = data
        ?.filter(x => !x.enabled)
        .map(app => {
            return (
                <LinkBox
                    as="article"
                    key={app.applicationTemplateId}
                    width="100%"
                    borderWidth="1px"
                    boxShadow="3px 3px 3px 0px rgba(0,0,0,0.1)"
                    borderRadius="5px"
                >
                    <Flex direction="column" justify="space-between" height="100%">
                        <Box>
                            <Box sx={{ height: "10px", backgroundColor: "#ccc", borderTopRadius: "5px" }}></Box>
                            <Box m="1rem">
                                <Heading mb="1rem" fontSize="lg" fontWeight="500" width="100%">
                                    {app.name}
                                </Heading>
                                <Box overflowX="clip">{app.description}</Box>
                            </Box>
                        </Box>

                        <Flex p="1rem" justify="flex-end" align="flex-end">
                            <Button
                                variant="outline"
                                colorScheme="green"
                                rightIcon={<IconCirclePlus />}
                                onClick={() => handleActivate(app)}
                                isLoading={selectedApplication?.applicationTemplateId === app.applicationTemplateId && enableAppIsPending}
                            >
                                Activate
                            </Button>
                        </Flex>
                    </Flex>
                </LinkBox>
            );
        });

    const appContainer =
        enabledApplications && enabledApplications.length > 0 ? (
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={3}>
                {enabledApplications}
            </SimpleGrid>
        ) : (
            <Box>
                <Text>No applications have been activated yet..</Text>
            </Box>
        );

    if (isLoadingApplications) {
        return <PageLoading text={"Loading Applications..."} />;
    }

    if (selectedEmbedApplication) {
        return (
            <Page
                title={selectedEmbedApplication.name}
                gutter={
                    <Box>
                        <Button variant="outline" onClick={() => setSelectedEmbedApplication(undefined)}>
                            Back to Applications
                        </Button>
                    </Box>
                }
            >
                <iframe src="https://www.youtube.com/embed/" height="100%" width="100%" />
            </Page>
        );
    }

    return (
        <Page title="Applications">
            {appContainer}
            <Box sx={{ position: "relative", py: 10 }}>
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                    <Text fontSize="sm">Available Applications</Text>
                </AbsoluteCenter>
            </Box>
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={3}>
                {appTemplates}
            </SimpleGrid>
            <ColorPaletteDialog
                palette={colorPalette}
                show={showColorPaletteDialog}
                color={selectedApplication?.appColor}
                onColorChanged={handleColorChanged}
                close={closeColorPaletteDialog}
            />
        </Page>
    );
};

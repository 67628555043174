export enum Urgency {
    High = 1,
    Medium = 2,
    Low = 3,
}

export const UrgencyOptions = Object.values(Urgency).filter(v => !isNaN(v as Urgency)) as Urgency[];

type UrgencyDetails = { displayName: string };

export const UrgencyLookup: Record<Urgency, UrgencyDetails> = {
    [Urgency.High]: { displayName: "High" },
    [Urgency.Medium]: { displayName: "Medium" },
    [Urgency.Low]: { displayName: "Low" },
};

export const displayUrgency = (UrgencyStatus: Urgency) => {
    return UrgencyLookup[UrgencyStatus].displayName;
};

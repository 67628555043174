import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import { useProjectAreaPermissions } from "../../hooks/index.ts";
import { ReportArea } from "../../auth/index.ts";
import { BaseReportsPage } from "../EngagementReportsPage/BaseReportsPage.tsx";
import { useCreateProjectWorkspace, useCurrentUser, useGetProjectWorkspace, useProject } from "../../api/apiQueries.ts";
import { CreateProjectWorkspaceArgs } from "../../api/api.ts";
import EnableReportsPage from "../EngagementReportsPage/EnableReportsPage.tsx";
import { PageLoading } from "am-tax-fe-core";

export const ProjectReportsPage: FunctionComponent = () => {
    const { engagementId, projectId } = useParams();
    const areaPermissions = useProjectAreaPermissions(ReportArea, engagementId, projectId);
    const currentUser = useCurrentUser().data;

    const project = useProject(engagementId, projectId);
    const { data: workspace, isLoading: isLoading } = useGetProjectWorkspace(engagementId!, projectId!);
    const { mutateAsync: createWorkspaceAsync, isPending } = useCreateProjectWorkspace();

    const handleWorkspace = async () => {
        const args: CreateProjectWorkspaceArgs = {
            engagementId: engagementId!,
            projectId: projectId!,
            workspaceName: project.data?.name ?? "",
        };
        await createWorkspaceAsync(args);
    };

    if (isLoading) {
        return <PageLoading text={"Page loading..."} />;
    }
    return (
        <>
            {!workspace?.workspaceId ? (
                <EnableReportsPage hasPermission={!!areaPermissions} onClick={handleWorkspace} isLoading={isPending} isInternaluser={currentUser!.isInternal} />
            ) : (
                <BaseReportsPage
                    engagementId={engagementId || ""}
                    projectId={projectId}
                    areaPermissions={areaPermissions}
                    isInternaluser={currentUser!.isInternal}
                />
            )}
        </>
    );
};

export default ProjectReportsPage;

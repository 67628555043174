import { useRef, FunctionComponent, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "am-tax-fe-core";
import { InputGroup, InputLeftAddon, Input, Select, Flex, FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

import { User, useUpdatePhoneNumber } from "../../api";
import { countryCodes } from "../../util/country-code-utils.ts";

type FormInputs = {
    countryCode: string;
    phoneNumber: string;
};

interface Props {
    user: User;
}

export const AccountForm: FunctionComponent<Props> = ({ user }) => {
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInputs>();

    const { mutate: updatePhoneNumber, isPending } = useUpdatePhoneNumber();

    const selectRef = useRef<HTMLSelectElement | null>(null);

    const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
    const setSelectMenuOpened = () => setIsSelectMenuOpen(true);
    const setSelectMenuClosed = () => setIsSelectMenuOpen(false);

    useEffect(() => {
        const curr = selectRef?.current;
        curr?.addEventListener("click", setSelectMenuOpened);
        curr?.addEventListener("mouseleave", setSelectMenuClosed);
        return () => {
            curr?.removeEventListener("click", setSelectMenuOpened, true);
            curr?.removeEventListener("mouseleave", setSelectMenuClosed, true);
        };
    }, [selectRef]);

    const onSubmit: SubmitHandler<FormInputs> = data => {
        const model = { userId: user.id || "", phoneCountryCode: data.countryCode, phoneNumber: data.phoneNumber };
        updatePhoneNumber(model);
    };

    const US_PHONE_NUMBER_PATTERN = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    const isPhoneNumberValid = (phoneNumber: string) => {
        const data = getValues();
        if (data.countryCode === "1") {
            if (phoneNumber.trim() !== "") {
                return US_PHONE_NUMBER_PATTERN.test(phoneNumber);
            }
        }
        return true;
    };

    const initialCountryCode = user.phoneCountryCode || countryCodes[0].code || "";

    // dev-note: deconstructing register to get hold of internal ref
    const { ref: countryCodeRef, ...countryCodeRest } = register("countryCode");

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.phoneNumber ? true : undefined}>
                <FormLabel>My Phone Number</FormLabel>
                <Flex gap=".5rem">
                    <InputGroup>
                        <InputLeftAddon sx={{ maxWidth: "8rem" }}>
                            <Select
                                sx={{ textAlign: "center" }}
                                variant="unstyled"
                                defaultValue={initialCountryCode}
                                {...countryCodeRest}
                                ref={e => {
                                    countryCodeRef(e);
                                    selectRef.current = e;
                                }}
                            >
                                {countryCodes.map(x => (
                                    <option key={x.iso} value={x.code}>
                                        {isSelectMenuOpen ? `${x.country} (+${x.code})` : `+${x.code}`}
                                    </option>
                                ))}
                            </Select>
                        </InputLeftAddon>
                        <Input
                            type="tel"
                            placeholder="Phone number"
                            defaultValue={user.phoneNumber || ""}
                            {...register("phoneNumber", { validate: { isBefore: isPhoneNumberValid } })}
                        />
                    </InputGroup>
                    <Button isLoading={isPending} type="submit">
                        Save
                    </Button>
                </Flex>
                <FormErrorMessage>Please fill in a valid phone number</FormErrorMessage>
            </FormControl>
        </form>
    );
};

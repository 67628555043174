import { FunctionComponent, useMemo } from "react";

import { Flex, Heading, VStack } from "@chakra-ui/react";
import { EngagementSummaryCard } from "./EngagementSummaryCard.tsx";
import { useEngagementRollup } from "../../api";
import { RepeatShimmer } from "am-tax-fe-core";
import { Page } from "../Page.tsx";

export const EngagementRollupPage: FunctionComponent = () => {
    const engagementRollupQuery = useEngagementRollup();

    const engagements = useMemo(
        () => (engagementRollupQuery.data ?? []).sort((a, b) => a.clientName.localeCompare(b.clientName)),
        [engagementRollupQuery.data],
    );

    let lastClientName = "";

    return (
        <Page title="Engagement Rollup">
            {engagementRollupQuery.isLoading && (
                <Flex direction={"column"} w={"100%"} gap={"1rem"}>
                    <RepeatShimmer times={3} h={"4rem"} borderRadius={"var(--chakra-radii-lg)"} />
                </Flex>
            )}

            <VStack alignItems={"start"} width={"fit-content"}>
                {engagements.map(engagementSummary => {
                    const retVal = (
                        <>
                            {lastClientName !== engagementSummary.clientName && (
                                <Heading variant={"info"} color={"blackAlpha.800"}>
                                    {engagementSummary.clientName}
                                </Heading>
                            )}
                            <EngagementSummaryCard summary={engagementSummary} />
                        </>
                    );
                    lastClientName = engagementSummary.clientName;
                    return retVal;
                })}
            </VStack>
        </Page>
    );
};

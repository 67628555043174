export enum StorageType {
    Box = 0,
    Blob = 1,
}

export const StorageTypes = Object.values(StorageType).filter(v => !isNaN(v as StorageType)) as StorageType[];

type StorageTypeDetails = { displayName: string };

export const StorageTypeLookup: Record<StorageType, StorageTypeDetails> = {
    [StorageType.Box]: { displayName: "Box" },
    [StorageType.Blob]: { displayName: "Blob" },
};

export const displayStorageType = (StorageType: StorageType) => {
    return StorageTypeLookup[StorageType].displayName;
};

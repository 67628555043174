import { Engagement, Project } from "../api";

const DEFAULT_REGION_ID = "standard";

type GroupByRegionsResult = { [regionId: string]: Project[] };

export const groupByRegions = function (projects: Project[]): GroupByRegionsResult {
    return projects.reduce(function (arr: GroupByRegionsResult, proj: Project) {
        const regionId = proj?.regionId ? `${proj.regionId}` : DEFAULT_REGION_ID;
        arr[regionId] = arr[regionId] || [];
        arr[regionId].push(proj);
        return arr;
    }, {});
};

export type ProjectIdsInOtherRegionsMap = { projectId: string; ids: string }[];

const getProjectIdsNotInEngagementRegionInternal = (groupByRegion: GroupByRegionsResult, engagementRegion: string) => {
    const projectIdsInOtherRegions: ProjectIdsInOtherRegionsMap = [];
    for (const regionId in groupByRegion) {
        if (regionId != engagementRegion) {
            const projects = groupByRegion[regionId];
            const firstProject = projects[0];
            const projectId = firstProject.projectId;
            const ids = projects.map(x => x.projectId).join(",");
            projectIdsInOtherRegions.push({ projectId, ids });
        }
    }
    return projectIdsInOtherRegions;
};

export const getEngagementProjectIdsStr = (engagement: Engagement, projects: Project[]) => {
    const projectGroups = groupByRegions(projects);
    const engagementRegion = engagement?.regionId ? `${engagement.regionId}` : DEFAULT_REGION_ID;
    const engagementRegionProjects = projectGroups[engagementRegion];
    return engagementRegionProjects?.map(x => x.projectId).join(",");
};

export const getProjectIdsNotInEngagementRegion = (engagement: Engagement, projects: Project[]) => {
    const projectGroups = groupByRegions(projects);
    const engagementRegion = engagement?.regionId ? `${engagement.regionId}` : DEFAULT_REGION_ID;
    return getProjectIdsNotInEngagementRegionInternal(projectGroups, engagementRegion);
};

import { FileContainer } from "am-tax-fe-core";

export function mapList(fileList: FileList) {
    const list: FileContainer[] = [];
    for (let i = 0; i < fileList.length; i++) {
        const item = fileList[i];
        list.push(map(item, i));
    }
    return list;
}

export function map(currentFile: File, index: number) {
    return {
        index,
        file: currentFile,
        relativeFolderPath: "",
        indeterminate: false,
        progress: 0,
        success: false,
        error: false,
        complete: false,
        show: true,
        cancelled: false,
        callbacks: {
            progress: () => {
                console.log("progress");
                return;
            },
            success: () => {
                console.log("success");
                return;
            },
            error: (error: string) => {
                console.log(error);
            },
        },
    } as FileContainer;
}

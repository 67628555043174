import { defineStyle, defineStyleConfig, extendTheme } from "@chakra-ui/react";
import { ChakraTheme } from "@chakra-ui/theme";
import { theme as coreTheme } from "am-tax-fe-core";

const themeOverrides = {
    colors: {
        red: {
            50: "#edd1d5",
            100: "#d38c97",
            200: "#c15e6d",
            300: "#b84758",
            400: "#b84758",
            500: "#af3043",
            600: "#a6192e", // brand secondary color
            700: "#951729",
            800: "#851425",
            900: "#741220",
        },
    },
    components: {
        Button: defineStyleConfig({
            variants: {
                naked: defineStyle({
                    height: "unset",
                    minWidth: "unset",
                }),
            },
        }),
    },
} as Partial<ChakraTheme>;

export const theme = extendTheme(coreTheme, themeOverrides);

import * as React from "react";
const SvgAmTaxLogoUsandukWhite = (props) => /* @__PURE__ */ React.createElement("svg", { id: "svg497", width: 384.70667, height: 143.21333, viewBox: "0 0 384.70667 143.21333", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs501" }), /* @__PURE__ */ React.createElement("g", { id: "g505", transform: "matrix(1.3333333,0,0,-1.3333333,0,143.21333)" }, /* @__PURE__ */ React.createElement("path", { d: "m 114.887,19.7332 h -1.662 v 46.3473 h 1.662 V 19.7332", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path509" }), /* @__PURE__ */ React.createElement("path", { d: "m 93.5215,57.9414 -2.6531,6.093 -2.7598,-6.093 z m -3.9328,8.141 h 2.7051 l 6.248,-13.8066 h -2.557 l -1.8102,4.1687 H 87.434 l -1.8875,-4.1687 h -2.4629 l 6.5051,13.8066", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path511" }), /* @__PURE__ */ React.createElement("path", { d: "m 90.9699,46.0789 c 0.4614,0.4313 0.686,0.9004 0.686,1.4242 0,0.4024 -0.1621,0.7574 -0.4996,1.0485 -0.3336,0.2898 -0.7915,0.4418 -1.3633,0.4418 -0.8676,0 -1.4703,-0.2329 -1.8012,-0.6938 -0.1773,-0.2371 -0.2602,-0.4933 -0.2602,-0.773 0,-0.3836 0.1297,-0.7532 0.3922,-1.1004 0.2664,-0.3512 0.6977,-0.8039 1.3164,-1.3696 0.7383,0.4211 1.2469,0.7622 1.5297,1.0223 m 0.1637,-7.7937 c 0.6238,0.3156 1.1086,0.6773 1.4406,1.0691 l -3.8988,3.7961 c -1.0945,-0.5848 -1.8012,-1.0336 -2.1395,-1.3469 -0.5164,-0.4695 -0.7711,-1.0426 -0.7711,-1.7199 0,-0.7293 0.3309,-1.2961 0.9969,-1.6867 0.6696,-0.3957 1.3824,-0.5875 2.1422,-0.5875 0.8625,0 1.6133,0.1586 2.2297,0.4758 m -5.0938,7.7136 c -0.2449,0.4594 -0.3714,0.909 -0.3714,1.3321 0,0.8902 0.3765,1.632 1.1328,2.2257 0.748,0.5883 1.75,0.8829 3.0133,0.8829 1.1933,0 2.1293,-0.2711 2.8054,-0.8219 0.6711,-0.55 1.0043,-1.2063 1.0043,-1.9649 0,-0.8879 -0.3484,-1.6625 -1.0387,-2.3324 -0.4082,-0.3867 -1.0855,-0.8367 -2.0378,-1.348 l 3.1425,-2.9969 c 0.193,0.5035 0.3262,0.8789 0.4024,1.1223 0.0808,0.2511 0.157,0.5961 0.2344,1.0476 h 2.0585 c -0.1281,-0.8918 -0.3957,-1.7363 -0.7964,-2.5504 -0.3957,-0.8054 -0.595,-1.1379 -0.595,-0.9742 l 3.0161,-2.9348 h -2.6813 l -1.6195,1.536 C 93.0727,37.6777 92.484,37.277 91.9527,37.0258 91.023,36.5805 89.9465,36.3609 88.7379,36.3609 c -1.7899,0 -3.0938,0.3832 -3.9078,1.152 -0.8067,0.7723 -1.2203,1.6375 -1.2203,2.5961 0,1.0488 0.3961,1.9101 1.1882,2.6062 0.4848,0.4211 1.3864,0.9489 2.7094,1.5918 -0.7254,0.6633 -1.2148,1.2368 -1.4676,1.6918", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path513" }), /* @__PURE__ */ React.createElement("path", { d: "m 82.5559,33.7523 h 3.3179 l 4.9438,-11.6746 4.9539,11.6746 H 99.073 V 19.9469 h -2.2031 v 8.1527 c 0,0.2813 0.0192,0.7457 0.0508,1.4016 0.0254,0.6504 0.0418,1.3504 0.0418,2.0926 l -5.002,-11.6469 h -2.321 l -4.977,11.6469 v -0.4211 c 0,-0.3426 0.0191,-0.8614 0.0445,-1.5551 0.0293,-0.691 0.0481,-1.1973 0.0481,-1.518 v -8.1527 h -2.1992 v 13.8054", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path515" }), /* @__PURE__ */ React.createElement("path", { d: "M 51.9078,107.412 0,19.825 H 12.0027 L 54.2215,94.1156 V 19.825 h 9.4113 v 87.587 h -11.725", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path517" }), /* @__PURE__ */ React.createElement("path", { d: "M 50.625,82.0777 41.1543,65.4305 V 19.825 h 9.4707 v 62.2527", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path519" }), /* @__PURE__ */ React.createElement("path", { d: "M 66.725,82.0777 76.193,65.4305 V 19.825 h -9.468 v 62.2527", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path521" }), /* @__PURE__ */ React.createElement("path", { d: "m 130.328,65.8723 h 49.485 V 55.4816 H 162.799 V 19.5043 h -15.457 v 35.9773 h -17.014 v 10.3907", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path523" }), /* @__PURE__ */ React.createElement("path", { d: "m 204.232,54.1828 h -0.13 l -6.429,-17.534 h 12.988 z m -9.417,11.6895 h 18.898 l 19.937,-46.368 h -16.56 l -2.792,7.5332 h -20.262 l -2.793,-7.5332 h -16.56 l 20.132,46.368", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path525" }), /* @__PURE__ */ React.createElement("path", { d: "m 250.6,43.5977 -17.989,22.2746 h 18.378 l 8.508,-12.4688 8.507,12.4688 h 18.379 L 268.394,43.5977 288.526,19.5043 h -19.158 l -9.871,14.0273 -9.871,-14.0273 H 230.468 L 250.6,43.5977", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path527" }), /* @__PURE__ */ React.createElement("path", { d: "M 0.886328,8.90352 H 3.50664 L 6.53906,2.1832 9.54766,8.90352 H 12.1438 V 0.242969 H 10.4332 L 10.5059,7.4 H 10.4816 L 7.27891,0.242969 H 5.72617 L 2.54805,7.4 H 2.52422 L 2.59688,0.242969 H 0.886328 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path529" }), /* @__PURE__ */ React.createElement("path", { d: "m 13.9195,8.90352 h 7.5211 V 7.54531 h -5.7015 v -2.1832 h 5.4711 V 4.00313 H 15.7391 V 1.60117 h 5.798 V 0.242969 H 13.9195 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path531" }), /* @__PURE__ */ React.createElement("path", { d: "M 22.9117,8.90352 H 25.532 L 28.5645,2.1832 31.573,8.90352 h 2.5961 V 0.242969 H 32.4586 L 32.5313,7.4 H 32.507 L 29.3043,0.242969 H 27.7516 L 24.5734,7.4 H 24.5496 L 24.6223,0.242969 H 22.9117 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path533" }), /* @__PURE__ */ React.createElement("path", { d: "m 37.7645,1.60117 h 3.2628 c 0.8133,0 1.6379,0.15781 1.6379,1.27383 0,0.80117 -0.5582,1.225 -1.3707,1.225 h -3.53 z m 0,3.78477 h 3.3964 c 0.5098,0 1.1766,0.12109 1.1766,1.1039 0,0.71524 -0.4363,1.05547 -1.4922,1.05547 h -3.0808 z m -1.8196,3.51758 h 5.7258 c 1.977,0 2.4867,-1.05508 2.4867,-2.15938 0,-1.23711 -0.7156,-1.58828 -1.1765,-1.80703 0.6668,-0.20625 1.5769,-0.69219 1.5769,-2.14727 0,-0.96992 -0.607,-2.546871 -2.9476,-2.546871 H 35.9449 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path535" }), /* @__PURE__ */ React.createElement("path", { d: "m 46.0535,8.90352 h 7.5207 V 7.54531 h -5.7015 v -2.1832 h 5.4711 V 4.00313 H 47.8727 V 1.60117 h 5.7984 V 0.242969 H 46.0535 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path537" }), /* @__PURE__ */ React.createElement("path", { d: "m 60.007,5.02188 c 1.0071,0 1.5528,0.30429 1.5528,1.27421 0,0.89688 -0.595,1.24922 -1.5528,1.24922 H 56.8648 V 5.02188 Z m -4.9613,3.88164 h 5.4828 c 2.2078,0 2.9235,-1.00664 2.9235,-2.35235 0,-1.12929 -0.6188,-1.83203 -1.4313,-2.08711 V 4.43984 c 1.0309,-0.21796 1.2613,-1.11601 1.2613,-2.34179 0,-0.41211 0.0121,-1.382034 0.3641,-1.855081 h -1.9652 c -0.1332,0.242187 -0.2184,0.678906 -0.2184,1.310161 0,1.23671 -0.1699,2.10976 -1.5043,2.10976 H 56.8648 V 0.242969 H 55.0457 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path539" }), /* @__PURE__ */ React.createElement("path", { d: "m 77.0191,4.57305 c 0,1.90508 -1.1644,3.21484 -3.2027,3.21484 -2.0379,0 -3.2023,-1.30976 -3.2023,-3.21484 0,-1.90391 1.1644,-3.21407 3.2023,-3.21407 2.0383,0 3.2027,1.31016 3.2027,3.21407 z m -8.2972,0 c 0,2.43906 1.4312,4.57343 5.0945,4.57343 3.6637,0 5.0953,-2.13437 5.0953,-4.57343 C 78.9117,2.13516 77.4801,0 73.8164,0 70.1531,0 68.7219,2.13516 68.7219,4.57305", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path541" }), /* @__PURE__ */ React.createElement("path", { d: "m 80.202,8.90352 h 7.2054 V 7.54531 h -5.3863 v -2.1832 h 5.0953 V 4.00313 H 82.0211 V 0.242969 H 80.202 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path543" }), /* @__PURE__ */ React.createElement("path", { d: "M 95.1512,7.54531 H 91.9 v 1.35821 h 8.322 V 7.54531 H 96.9711 V 0.242969 H 95.1512 V 7.54531", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path545" }), /* @__PURE__ */ React.createElement("path", { d: "M 106.06,3.74805 104.58,7.4 103.076,3.74805 Z m -2.535,5.15547 h 2.159 l 3.857,-8.660551 h -2.026 l -0.909,2.146871 h -4.076 l -0.898,-2.146871 h -1.9652 l 3.8582,8.660551", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path547" }), /* @__PURE__ */ React.createElement("path", { d: "m 112.82,4.70703 -3.299,4.19649 h 2.268 l 2.159,-3.0086 2.245,3.0086 h 2.195 l -3.36,-4.19649 3.542,-4.464061 h -2.305 L 113.912,3.4332 111.534,0.242969 h -2.244 l 3.53,4.464061", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path549" }), /* @__PURE__ */ React.createElement("path", { d: "M 124.712,3.74805 123.232,7.4 121.727,3.74805 Z m -2.536,5.15547 h 2.16 l 3.857,-8.660551 h -2.026 l -0.909,2.146871 h -4.076 l -0.898,-2.146871 h -1.965 l 3.857,8.660551", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path551" }), /* @__PURE__ */ React.createElement("path", { d: "m 128.973,8.90352 h 2.087 l 5.155,-6.50157 h 0.024 v 6.50157 h 1.747 V 0.242969 h -2.05 L 130.744,6.74414 H 130.72 V 0.242969 h -1.747 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path553" }), /* @__PURE__ */ React.createElement("path", { d: "m 141.581,1.60117 h 2.559 c 1.31,0 2.705,0.425 2.705,2.95977 0,2.81406 -1.783,2.98437 -2.802,2.98437 h -2.462 z m -1.82,7.30235 h 4.67 c 3.397,0 4.234,-1.94063 4.234,-4.41563 0,-1.86797 -0.849,-4.244921 -3.797,-4.244921 h -5.107 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path555" }), /* @__PURE__ */ React.createElement("path", { d: "m 163.604,0.242969 h -1.068 L 162.281,1.36992 C 161.578,0.521094 160.341,0 158.982,0 c -3.664,0 -5.095,2.13516 -5.095,4.57305 0,2.43906 1.516,4.57343 5.046,4.57343 2.633,0 4.319,-1.33398 4.513,-3.03242 h -1.965 c -0.328,1.17578 -1.42,1.67383 -2.597,1.67383 -2.244,0 -3.105,-1.3707 -3.105,-3.275 0,-2.30508 1.48,-3.15391 3.009,-3.15391 1.856,0 2.705,0.84883 2.996,2.28008 h -2.45 v 1.35899 h 4.27 V 0.242969", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path557" }), /* @__PURE__ */ React.createElement("path", { d: "m 165.379,8.90352 h 1.819 V 1.67383 h 5.265 V 0.242969 h -7.084 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path559" }), /* @__PURE__ */ React.createElement("path", { d: "m 181.285,4.57305 c 0,1.90508 -1.165,3.21484 -3.203,3.21484 -2.038,0 -3.202,-1.30976 -3.202,-3.21484 0,-1.90391 1.164,-3.21407 3.202,-3.21407 2.038,0 3.203,1.31016 3.203,3.21407 z m -8.298,0 c 0,2.43906 1.432,4.57343 5.095,4.57343 3.664,0 5.095,-2.13437 5.095,-4.57343 C 183.177,2.13516 181.746,0 178.082,0 c -3.663,0 -5.095,2.13516 -5.095,4.57305", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path561" }), /* @__PURE__ */ React.createElement("path", { d: "m 186.287,1.60117 h 3.263 c 0.813,0 1.638,0.15781 1.638,1.27383 0,0.80117 -0.558,1.225 -1.371,1.225 h -3.53 z m 0,3.78477 h 3.396 c 0.51,0 1.177,0.12109 1.177,1.1039 0,0.71524 -0.436,1.05547 -1.492,1.05547 h -3.081 z m -1.82,3.51758 h 5.726 c 1.977,0 2.487,-1.05508 2.487,-2.15938 0,-1.23711 -0.716,-1.58828 -1.177,-1.80703 0.667,-0.20625 1.577,-0.69219 1.577,-2.14727 0,-0.96992 -0.607,-2.546871 -2.947,-2.546871 h -5.666 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path563" }), /* @__PURE__ */ React.createElement("path", { d: "M 199.974,3.74805 198.494,7.4 196.99,3.74805 Z m -2.535,5.15547 h 2.159 l 3.857,-8.660551 h -2.026 L 200.52,2.38984 h -4.076 l -0.898,-2.146871 h -1.965 l 3.858,8.660551", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path565" }), /* @__PURE__ */ React.createElement("path", { d: "m 204.236,8.90352 h 1.819 V 1.67383 h 5.265 V 0.242969 h -7.084 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path567" }), /* @__PURE__ */ React.createElement("path", { d: "m 216.601,8.90352 h 2.087 l 5.155,-6.50157 h 0.024 v 6.50157 h 1.747 V 0.242969 h -2.05 l -5.192,6.501171 h -0.024 V 0.242969 h -1.747 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path569" }), /* @__PURE__ */ React.createElement("path", { d: "m 227.389,8.90352 h 7.521 V 7.54531 h -5.701 v -2.1832 h 5.471 V 4.00313 h -5.471 V 1.60117 h 5.798 V 0.242969 h -7.618 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path571" }), /* @__PURE__ */ React.createElement("path", { d: "m 238.747,7.54531 h -3.251 v 1.35821 h 8.322 V 7.54531 h -3.251 V 0.242969 h -1.82 V 7.54531", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path573" }), /* @__PURE__ */ React.createElement("path", { d: "m 243.748,8.90352 h 1.965 l 1.953,-6.72032 h 0.024 l 2.159,6.72032 h 1.893 l 2.171,-6.72032 h 0.025 l 1.989,6.72032 h 1.893 l -2.912,-8.660551 h -1.965 l -2.16,6.646871 h -0.024 l -2.171,-6.646871 h -1.965 l -2.875,8.660551", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path575" }), /* @__PURE__ */ React.createElement("path", { d: "m 266.449,4.57305 c 0,1.90508 -1.164,3.21484 -3.202,3.21484 -2.038,0 -3.203,-1.30976 -3.203,-3.21484 0,-1.90391 1.165,-3.21407 3.203,-3.21407 2.038,0 3.202,1.31016 3.202,3.21407 z m -8.297,0 c 0,2.43906 1.431,4.57343 5.095,4.57343 3.663,0 5.095,-2.13437 5.095,-4.57343 C 268.342,2.13516 266.91,0 263.247,0 c -3.664,0 -5.095,2.13516 -5.095,4.57305", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path577" }), /* @__PURE__ */ React.createElement("path", { d: "m 274.593,5.02188 c 1.007,0 1.553,0.30429 1.553,1.27421 0,0.89688 -0.595,1.24922 -1.553,1.24922 h -3.142 V 5.02188 Z m -4.961,3.88164 h 5.483 c 2.208,0 2.923,-1.00664 2.923,-2.35235 0,-1.12929 -0.618,-1.83203 -1.431,-2.08711 V 4.43984 c 1.031,-0.21796 1.262,-1.11601 1.262,-2.34179 0,-0.41211 0.012,-1.382034 0.364,-1.855081 h -1.966 c -0.133,0.242187 -0.218,0.678906 -0.218,1.310161 0,1.23671 -0.17,2.10976 -1.504,2.10976 h -3.094 V 0.242969 h -1.819 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path579" }), /* @__PURE__ */ React.createElement("path", { d: "m 279.522,8.90352 h 1.819 V 4.88906 l 4.428,4.01446 h 2.499 l -4.039,-3.51758 4.294,-5.142971 H 286.06 L 282.809,4.23398 281.341,2.93516 V 0.242969 h -1.819 V 8.90352", style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0.1
}, id: "path581" })));
export default SvgAmTaxLogoUsandukWhite;

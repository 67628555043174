import { useMemo, useState } from "react";
import { DeleteProjectEventArgs, PostProjectEventArgs, PutProjectEventArgs, useDeleteProjectEvent, useProjectEvent, useSaveProjectEvent } from "../../api";
import { useProjectAreaPermissions } from "../../hooks";
import { CalendarArea } from "../../auth";
import { Mutation } from "../../util/queryUtils.ts";
import { DeleteEventArgs, EventFormProps, SaveEventArgs } from "../EngagementEventPage/EventForm.tsx";

export function useEventFormForProject(engagementId: string, projectId: string, eventId: string): Omit<EventFormProps, "returnTo"> {
    const [deleting, setDeleting] = useState(false);
    const eventQuery = useProjectEvent(engagementId, projectId, eventId, !deleting);
    const areaPermissions = useProjectAreaPermissions(CalendarArea, engagementId, projectId);
    const saveEventQuery = useSaveProjectEvent();
    const deleteEventQuery = useDeleteProjectEvent();

    const saveEventMutation = useMemo(() => {
        class SaveMutation extends Mutation<PostProjectEventArgs | PutProjectEventArgs> {
            updateArgs(args: PostProjectEventArgs | PutProjectEventArgs) {
                return { ...args, projectId };
            }
        }
        return new SaveMutation(saveEventQuery) as Mutation<SaveEventArgs>;
    }, [projectId, saveEventQuery]);

    const deleteEventMutation = useMemo(() => {
        class DeleteMutation extends Mutation<DeleteProjectEventArgs> {
            updateArgs(args: DeleteProjectEventArgs) {
                return { ...args, projectId };
            }
        }
        return new DeleteMutation(deleteEventQuery) as Mutation<DeleteEventArgs>;
    }, [projectId, deleteEventQuery]);

    return {
        setDeleting,
        eventQuery,
        areaPermissions,
        saveEventMutation,
        deleteEventMutation,
    };
}

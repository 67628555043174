import { TreeNode } from "../../api";

export const findNodeByKeyRecursively = (searchKey: string, node: TreeNode): TreeNode | undefined => {
    if (node.key === searchKey) {
        return node;
    }

    if (!node.children) {
        return undefined;
    }

    // Recursively search in each child node.
    for (const child of node.children) {
        const result = findNodeByKeyRecursively(searchKey, child);
        if (result) {
            return result;
        }
    }
    return undefined;
};

export const findNodeByKey = (key: string, nodes: TreeNode[]): TreeNode | undefined => {
    for (let i = 0; i < nodes.length; i++) {
        const result = findNodeByKeyRecursively(key, nodes[i]);
        if (result) {
            return result;
        }
    }
};

export const resetSelectionRecursively = (node: TreeNode): TreeNode | undefined => {
    node.selected = false;

    if (!node.children) {
        return;
    }
    // Recursively search in each child node.
    for (const child of node.children) {
        resetSelectionRecursively(child);
    }
};

export const resetSelection = (nodes: TreeNode[]) => {
    for (let i = 0; i < nodes.length; i++) {
        resetSelectionRecursively(nodes[i]);
    }
};

export const setSelectionRecursively = (node: TreeNode, defaultValue: string[] | string): TreeNode | undefined => {
    let hasDefaultValue = false;
    if (Array.isArray(defaultValue)) {
        hasDefaultValue = defaultValue.find(x => x === node.key) !== undefined;
    } else {
        hasDefaultValue = defaultValue === node.key;
    }
    node.selected = hasDefaultValue;

    if (!node.children) {
        return;
    }
    // Recursively search in each child node.
    for (const child of node.children) {
        setSelectionRecursively(child, defaultValue);
    }
};

export const setSelection = (nodes: TreeNode[], defaultValue: string[] | string) => {
    for (let i = 0; i < nodes.length; i++) {
        setSelectionRecursively(nodes[i], defaultValue);
    }
};

export const getNodeSelectionRecursively = (node: TreeNode): TreeNode[] => {
    const selected: TreeNode[] = [];
    if (node.selected) {
        selected.push(node);
    }

    if (!node.children) {
        return selected;
    }
    // Recursively search in each child node.
    for (const child of node.children) {
        const subSelected = getNodeSelectionRecursively(child);
        if (subSelected.length > 0) {
            selected.push(...subSelected);
        }
    }

    return selected;
};

export const getNodeSelection = (nodes: TreeNode[]) => {
    const selected: TreeNode[] = [];
    for (let i = 0; i < nodes.length; i++) {
        selected.push(...getNodeSelectionRecursively(nodes[i]));
    }
    return selected;
};

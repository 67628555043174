//import { AMTaxLogoGlobalWhite, LoginPage, MsalLoginForm } from "am-tax-fe-core";
import { MsalLoginForm } from "am-tax-fe-core";
import BgImage from "../../assets/images/8C0862F6-1A5B-4941-8C67-874FCFAD2E05.jpg";
import EngageLogo from "../../assets/images/engage-logo-white-expanded.svg?react";
import { LocalLoginPage } from "./LocalLoginPage";
import { Icon } from "@chakra-ui/react";

export const UnauthenticatedLandingPage = () => {
    const logo = <Icon as={EngageLogo} boxSize={{ base: "12rem", sm: "14rem", xl: "16rem", "2xl": "20rem" }} />;
    return (
        <LocalLoginPage bgImage={`url(${BgImage})`} appName={import.meta.env.VITE_APP_NAME} logo={logo}>
            <MsalLoginForm />
        </LocalLoginPage>
    );
};

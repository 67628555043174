import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router-dom";
import { getLeftNavExpandedPref, LeftNav as LeftNavCore, NavGroup, NavItemProps } from "am-tax-fe-core";
import { IconAppWindow, IconHomeSearch, IconSearch } from "@tabler/icons-react";

import { NavItemResponse, useCurrentUser, useNavItems } from "../../api/index.ts";
import { NavArea, NavDestination } from "../../types/enums.ts";
import { useAppPermissions } from "../../hooks/index.ts";
import { AdminArea } from "../../auth/index.ts";
import { useTranslation } from "react-i18next";
import NavFooter from "./NavFooter.tsx";
import { useEngagementAppItems } from "./useEngagementAppItems.tsx";
import { useEngagementOrProjectGroup } from "./useEngagementOrProjectGroup.tsx";
import { useProjectAppItems } from "./useProjectAppItems.tsx";

import EngageLogoExpanded from "../../assets/images/engage-logo-white-expanded.svg?react";
import EngageLogoCollapsed from "../../assets/images/engage-logo-white-collapsed.svg?react";

export const LeftNav: FunctionComponent = () => {
    const { t } = useTranslation("LeftNav");

    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data;

    const matchesEngagement = useMatch("/engagement/:engagementId/*");
    const engagementIdParam = matchesEngagement?.params?.engagementId ?? "";

    const matchesProject = useMatch("/engagement/:engagementId/project/:projectId/*");
    const projectIdParam = matchesProject?.params?.projectId ?? "";

    const [expanded, setExpandedState] = useState(getLeftNavExpandedPref());

    const logo = useMemo(() => {
        return expanded ? <EngageLogoExpanded width="200px" height="60px" /> : <EngageLogoCollapsed width="2rem" height="2rem" />;
    }, [expanded]);

    const navItemsQuery = useNavItems();
    const topItems = useMemo(() => (navItemsQuery.data ?? []).filter(item => item.area === NavArea.Top), [navItemsQuery.data]);

    const icons = useMemo(() => ({}), []);
    // this is costs 2MB in page weight and no one is using it, so I'm disabling for now
    /*
        const hasDynmaicNavItems = useMemo(() => {
            return !!navItemsQuery.data?.length || !!engagementNavItemsQuery.data?.length;
        }, [navItemsQuery.data, engagementNavItemsQuery.data]);

        const [icons, setIcons] = useState<Record<string, FC<{ size: string }>>>({});
        useEffect(() => {
            if (hasDynmaicNavItems) {
                import("@tabler/icons-react").then(module => {
                    // @ts-expect-error - not worth the effort to get the types to work here
                    setIcons(module);
                });
            }
        }, [hasDynmaicNavItems]);
    */

    const appPermissions = useAppPermissions();

    const { group: engagementOrProjectNavGroup } = useEngagementOrProjectGroup(engagementIdParam, projectIdParam);

    const { items: engagementItems } = useEngagementAppItems(engagementIdParam);
    const { items: projectItems } = useProjectAppItems(engagementIdParam, projectIdParam);
    let appItems: Array<NavGroup | NavItemProps> = [];
    if (projectIdParam) {
        appItems = projectItems;
    } else if (engagementIdParam) {
        appItems = engagementItems;
    }

    const getDynamicNavItem = (_icons: Record<string, FunctionComponent<{ size: string }>>, navItem: NavItemResponse): NavItemProps => {
        const to = navItem.destination === NavDestination.Iframe ? `/navItem/${navItem.id}` : navItem.href;
        const Icon = IconAppWindow;
        return { to, leftIcon: <Icon size="1rem" />, text: navItem.linkText };
    };

    const items = useMemo(() => {
        const items: Array<NavGroup | NavItemProps> = [
            { to: "/myEngagements", leftIcon: <IconHomeSearch size="1rem" />, text: t("myEngagements") },
            // { to: "/engagementRollup", leftIcon: <IconAssembly size="1rem" />, text: "Engagement Rollup" },
        ];

        if (currentUser?.isInternal) {
            items.push({ to: "/search", leftIcon: <IconSearch size="1rem" />, text: t("search") });
        }

        if (topItems.length) {
            for (const navItem of topItems) {
                items.push(getDynamicNavItem(icons, navItem));
            }
        }

        if (engagementIdParam || projectIdParam) {
            items.push(engagementOrProjectNavGroup);
        }

        return items;
    }, [currentUser?.isInternal, topItems, engagementIdParam, projectIdParam, icons, engagementOrProjectNavGroup, t]);

    const itemsFooter = <NavFooter show={appPermissions.has(AdminArea.update)} expanded={expanded} />;

    return <LeftNavCore logo={logo} items={items.concat(appItems)} itemsFooter={itemsFooter} onSizeChange={setExpandedState} />;
};

import { FunctionComponent } from "react";

import { FileContainer, FileUploader } from "am-tax-fe-core";

export interface Props {
    immediateUpload?: boolean;
    fileQueue: FileContainer[];
    setFileQueue: (q: FileContainer[]) => void;
    uploadFile: (q: FileContainer) => void;
    onUploadComplete: () => void;
}

export const UploadPanel: FunctionComponent<Props> = ({ immediateUpload, fileQueue, setFileQueue, uploadFile, onUploadComplete }) => {
    const onFileAdded = async (fileContainer: FileContainer) => {
        if (immediateUpload) {
            uploadFile(fileContainer);
        } else {
            const queue = [...fileQueue];
            queue.push(fileContainer);
            setFileQueue(queue);
        }
    };
    const onFileCancelled = async (fileContainer: FileContainer) => {
        const updatedQueue = [];
        const queue = [...fileQueue];
        for (const file of queue) {
            if (file.index !== fileContainer.index) {
                updatedQueue.push(file);
            }
        }
        setFileQueue(updatedQueue);
    };

    return <FileUploader flattenFolders={true} onFileAdded={onFileAdded} onFileCancelled={onFileCancelled} onBatchUploaded={onUploadComplete} />;
};

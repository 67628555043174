import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { Page } from "../Page.tsx";
import { Box, Card, CardBody, Grid, GridItem, Heading, HStack, Link, Switch, Text, VStack } from "@chakra-ui/react";
import { useCurrentUser } from "../../api";
import { applyParams, appNameAtom, Avatar, gdprConsentService, getAccount, toIsoFormat, useLocalizationSettingsModal } from "am-tax-fe-core";
import { useMsal } from "@azure/msal-react";
import { useAtomValue } from "jotai";

const mailTo = "EngageSupport@alvarezandmarsal.com";

const dataRequestSubject = "Data Request - {appName}";
const dataRequestBody = "This is a formal request for a copy of my data that resides in {appName}. My account's email is {email}.";

const deleteRequestSubject = "Data Removal Request - {appName}";
const deleteRequestBody = "This is a formal request to remove the data associated with my account in {appName}. My account's email is {email}.";

export const ManageMyDataPage: FunctionComponent = () => {
    const userQuery = useCurrentUser();
    const { instance } = useMsal();
    const appName = useAtomValue(appNameAtom);

    const name = useMemo(() => {
        const account = getAccount(instance);
        const name = account?.name || account?.idTokenClaims?.givenname + " " + account?.idTokenClaims?.surname;
        if (name.trim()) {
            return name;
        }
    }, [instance]);

    const [consentGiven, setConsentGiven] = useState(false);
    useEffect(() => {
        (async () => {
            const consent = await gdprConsentService.loadConsent();
            setConsentGiven(consent?.consentGiven ?? false);
        })();
    }, []);
    const toggleConsent = useCallback(() => {
        (async () => {
            await gdprConsentService.saveConsent({ consentGiven: !consentGiven, consentGivenDate: toIsoFormat(new Date()) });
            setConsentGiven(!consentGiven);
        })();
    }, [consentGiven]);

    const { openModal: openLocalizationSettingsModal } = useLocalizationSettingsModal();

    return (
        <Page title="Manage My Data">
            <Grid
                templateAreas={{
                    base: `"myData"
                  "aboutMyData"
                   "actions"`,
                    lg: `"myData aboutMyData"
                  "actions aboutMyData"`,
                }}
                gridTemplateColumns={{ base: "auto", lg: "1fr 2fr" }}
                gap={"2rem"}
            >
                <Card as={GridItem} area={"myData"}>
                    <CardBody>
                        <VStack spacing={"1.5rem"}>
                            <VStack w={"100%"}>
                                <Avatar name={name} size={"md"} />
                                <Box textAlign={"center"}>
                                    <Text>
                                        {userQuery.data?.firstName} {userQuery.data?.lastName}
                                    </Text>
                                    <Text>{userQuery.data?.email}</Text>
                                </Box>
                            </VStack>
                            <Box>
                                <Link as={NavLink} textDecoration={"underline"} onClick={openLocalizationSettingsModal}>
                                    Localization Preferences
                                </Link>
                            </Box>
                        </VStack>
                    </CardBody>
                </Card>
                <Card as={GridItem} area={"actions"}>
                    <CardBody>
                        <Heading fontWeight={500}>Actions</Heading>
                        <VStack align={"start"} spacing={"1.5rem"}>
                            <HStack>
                                <Text>Allow my usage statistics to be used for quality and improvement purposes</Text>
                                <Switch isChecked={consentGiven} onChange={toggleConsent} />
                            </HStack>
                            <Box>
                                <Link
                                    textDecoration={"underline"}
                                    href={`mailto:${mailTo}?subject=${encodeURIComponent(applyParams(dataRequestSubject, { appName }))}&body=${encodeURIComponent(applyParams(dataRequestBody, { appName, email: userQuery.data?.email }))}`}
                                >
                                    Request a copy of my data
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    textDecoration={"underline"}
                                    href={`mailto:${mailTo}?subject=${encodeURIComponent(applyParams(deleteRequestSubject, { appName }))}&body=${encodeURIComponent(applyParams(deleteRequestBody, { appName, email: userQuery.data?.email }))}`}
                                >
                                    Remove my data
                                </Link>
                            </Box>
                        </VStack>
                    </CardBody>
                </Card>
                <Card as={GridItem} area={"aboutMyData"}>
                    <CardBody>
                        <Heading fontWeight={500}>About My Data</Heading>
                        <VStack align={"start"} spacing={"1.5rem"}>
                            <Box>
                                <Heading fontSize={"1rem"}>Where is my data stored and for how long?</Heading>
                                <Text variant={"info"}>
                                    Data in {appName} is geographically segregated based on the configured region for each engagement/project, and is encrypted
                                    on a secured servers in those geographical regions. It is retained as required by law for 7 to 10 years.
                                </Text>
                            </Box>
                            <Box>
                                <Heading fontSize={"1rem"}>How is the data used/processed?</Heading>
                                <Text variant={"info"}>
                                    The documents uploaded to this system are carefully protected and only made available to the individuals granted access to
                                    the engagement and/or projects in which the documents reside. They are used only for the intended purpose of the
                                    project/engagement to which they belong.
                                </Text>
                            </Box>
                            <Box>
                                <Heading fontSize={"1rem"}>Who can see this data?</Heading>
                                <Text variant={"info"}>
                                    Access to engagements is carefully controlled. Only those that have access to engagements can see documents and/or actions
                                    within those engagements.
                                </Text>
                            </Box>
                            <Box>
                                <Heading fontSize={"1rem"}>Is my data shared?</Heading>
                                <Text variant={"info"}>The data in this system is carefully protected and is not shared.</Text>
                            </Box>
                        </VStack>
                    </CardBody>
                </Card>
            </Grid>
        </Page>
    );
};

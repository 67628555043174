import { useMemo, useState } from "react";
import { useDeleteEngagementEvent, useEngagementEvent, useSaveEngagementEvent } from "../../api";
import { useEngagementAreaPermissions } from "../../hooks";
import { CalendarArea } from "../../auth";
import { Mutation } from "../../util/queryUtils.ts";
import { EventFormProps } from "./EventForm.tsx";

export function useEventFormForEngagement(engagementId: string, eventId: string): Omit<EventFormProps, "returnTo"> {
    const [deleting, setDeleting] = useState(false);
    const eventQuery = useEngagementEvent(engagementId, eventId, !deleting);
    const areaPermissions = useEngagementAreaPermissions(CalendarArea, engagementId);
    const saveEventQuery = useSaveEngagementEvent();
    const deleteEventQuery = useDeleteEngagementEvent();
    const saveEventMutation = useMemo(() => new Mutation(saveEventQuery), [saveEventQuery]);
    const deleteEventMutation = useMemo(() => new Mutation(deleteEventQuery), [deleteEventQuery]);
    return { setDeleting, eventQuery, areaPermissions, saveEventMutation, deleteEventMutation };
}

import { FunctionComponent, useRef, useState } from "react";

import { Box, Button, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, DrawerOverlay, DrawerBody, DrawerFooter } from "@chakra-ui/react";

import ConversationList from "./ConversationList";
import CreateConversationDialog from "./CreateConversationDialog";

interface Props {
    engagementId: string;
    projectId?: string;
    requestId: string;
    show: boolean;
    toggleShow: (show: boolean) => void;
}

const ConversationDrawer: FunctionComponent<Props> = ({ engagementId, projectId, requestId, show, toggleShow }: Props) => {
    const [showCreateConversationDialog, setShowCreateConversationDialog] = useState(false);

    const focusAfterClosingRef = useRef(null);

    return (
        <>
            <Drawer isOpen={show} placement="right" onClose={() => toggleShow(!show)} finalFocusRef={focusAfterClosingRef} size="xl">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Conversation History</DrawerHeader>

                    <DrawerBody>
                        <Box>
                            <ConversationList engagementId={engagementId} projectId={projectId} requestId={requestId} />
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button m="1rem" variant="primary" onClick={() => setShowCreateConversationDialog(true)} ref={focusAfterClosingRef}>
                            Start a new Conversation
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <CreateConversationDialog
                requestId={requestId}
                engagementId={engagementId}
                projectId={projectId}
                show={showCreateConversationDialog}
                toggleShow={setShowCreateConversationDialog}
                focusAfterClosingRef={focusAfterClosingRef}
            />
        </>
    );
};

export default ConversationDrawer;

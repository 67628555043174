import { FunctionComponent, useMemo, useState } from "react";
import { AgressoEngagement, Engagement, useClientEngagements } from "../../api";
import { Filter, Shimmer } from "am-tax-fe-core";
import { Box, Button, Divider, Flex, Heading, Icon, Spacer, VStack } from "@chakra-ui/react";
import { IconChevronRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { EngagmentIcon } from "../../util/engagementUtils.ts";
import { AccessStatus } from "../../enums/AccessStatus.ts";

export interface EngagementsListProps {
    clientId: string | undefined;
    selectedIndex: number | undefined;
    onEngagementSelected: (selectedIndex: number) => void;
}

export const EngagementList: FunctionComponent<EngagementsListProps> = ({ clientId, selectedIndex, onEngagementSelected }) => {
    const engagementsQuery = useClientEngagements(clientId);
    const navigate = useNavigate();

    const [filter, setFilter] = useState("");
    const { engagements, agressoEngagements } = useMemo(
        () => ({ engagements: engagementsQuery.data?.engagements || [], agressoEngagements: engagementsQuery.data?.agressoEngagements || [] }),
        [engagementsQuery.data],
    );

    function getFilterString(engagement: Engagement | AgressoEngagement) {
        return ((engagement as Engagement).engagementId || "") + engagement.name;
    }

    const { provisioned, unprovisioned } = useMemo(() => {
        return {
            provisioned: filter.length
                ? engagements.filter(engagement => getFilterString(engagement).toLowerCase().includes(filter.toLowerCase()))
                : engagements,
            unprovisioned: filter.length
                ? agressoEngagements.filter(engagement => getFilterString(engagement).toLowerCase().includes(filter.toLowerCase()))
                : agressoEngagements,
        };
    }, [engagements, agressoEngagements, filter]);

    const provisionEngagement = (engagement: AgressoEngagement) => {
        navigate(`./client/${clientId}/provisionEngagement/${engagement.projectCode}/${encodeURIComponent(engagement.name)}`);
    };

    const goToEngagementDashboard = (engagementId: string) => {
        navigate(`/engagement/${engagementId}/dashboard`);
    };

    return (
        <VStack bgColor="blue.25" bgGradient={`linear(to-br, blue.25, blue.50)`} padding="2rem 0 2rem 2rem" alignItems="stretch" height="100%" spacing={"1rem"}>
            <Box marginRight="2rem">
                <Filter
                    placeholder="Filter Engagements"
                    onChange={setFilter}
                    count={{ total: engagements.length, shown: provisioned.length + unprovisioned.length }}
                />
            </Box>

            {!!clientId && !engagementsQuery.isLoading && provisioned.length + unprovisioned.length === 0 && <Box textAlign="center">No Engagements Found</Box>}

            {engagementsQuery.isLoading && (
                <VStack
                    spacing="1px"
                    alignItems="stretch"
                    overflowY="auto"
                    divider={
                        <Box paddingRight="2rem" border={0}>
                            <Divider borderColor="blue.200" />
                        </Box>
                    }
                >
                    {Array(1)
                        .fill(null)
                        .map((val, i) => (
                            <Shimmer key={i} height="40px" marginRight="2rem" />
                        ))}
                </VStack>
            )}

            {provisioned.length > 0 && (
                <VStack
                    spacing="1px"
                    alignItems="stretch"
                    overflowY="auto"
                    divider={
                        <Box paddingRight="2rem" border={0}>
                            <Divider borderColor="blue.200" />
                        </Box>
                    }
                >
                    {provisioned?.map((engagement, i) => (
                        <Flex
                            as={Button}
                            key={engagement.projectCode}
                            rightIcon={i === selectedIndex ? <IconChevronRight /> : <></>}
                            justifyContent="space-between"
                            gap={".5rem"}
                            onClick={() => {
                                if (i === selectedIndex && engagement.accessStatus === AccessStatus.Accepted) {
                                    goToEngagementDashboard(engagement.engagementId);
                                } else {
                                    onEngagementSelected(i);
                                }
                            }}
                            isDisabled={
                                i === selectedIndex && (engagement.accessStatus === AccessStatus.Denied || engagement.accessStatus === AccessStatus.Requested)
                            }
                            borderRadius={0}
                            paddingRight="2rem"
                            color={"blue.900"}
                            _hover={{ bg: i === selectedIndex ? "blueAlpha.100" : "whiteAlpha.800" }}
                            backgroundColor={i === selectedIndex ? "white" : "transparent"}
                            fontWeight={"normal"}
                        >
                            <Box overflow={"hidden"} textOverflow={"ellipsis"}>
                                {engagement.projectCode} - {engagement.name}
                            </Box>
                            <Spacer />
                            <Box>{i === selectedIndex && <Icon as={EngagmentIcon} boxSize={"1.5rem"} />}</Box>
                        </Flex>
                    ))}
                </VStack>
            )}

            {unprovisioned.length > 0 && (
                <>
                    <Heading variant={"info"} alignSelf={"center"} pt={"1rem"}>
                        Unprovisioned Engagements
                    </Heading>

                    <VStack
                        spacing="1px"
                        alignItems="stretch"
                        overflowY="auto"
                        divider={
                            <Box paddingRight="2rem" border={0}>
                                <Divider borderColor="blue.200" />
                            </Box>
                        }
                    >
                        {unprovisioned?.map((engagement, i) => (
                            <Flex
                                as={Button}
                                key={engagement.projectCode}
                                rightIcon={provisioned.length + i === selectedIndex ? <IconChevronRight /> : <></>}
                                justifyContent="space-between"
                                gap={".5rem"}
                                onClick={() => {
                                    if (provisioned.length + i === selectedIndex) {
                                        provisionEngagement(engagement);
                                    } else {
                                        onEngagementSelected(provisioned.length + i);
                                    }
                                }}
                                borderRadius={0}
                                paddingRight="2rem"
                                color={"gray.400"}
                                _hover={{ bg: provisioned.length + i === selectedIndex ? "blueAlpha.100" : "whiteAlpha.800" }}
                                backgroundColor={provisioned.length + i === selectedIndex ? "white" : "transparent"}
                                fontWeight={"normal"}
                            >
                                <Box overflow={"hidden"} textOverflow={"ellipsis"}>
                                    {engagement.projectCode} - {engagement.name}
                                </Box>
                                <Spacer />
                                <Box>{provisioned.length + i === selectedIndex && <Icon as={EngagmentIcon} boxSize={"1.5rem"} />}</Box>
                            </Flex>
                        ))}
                    </VStack>
                </>
            )}
        </VStack>
    );
};

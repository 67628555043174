import { useMemo } from "react";

import { NavGroup, NavItemProps } from "am-tax-fe-core";
import { IconAppWindow } from "@tabler/icons-react";
import { Flex } from "@chakra-ui/react";

import { NavItemResponse, useApplications, useNavItems, useNavItemsForEngagement } from "../../api/index.ts";
import { NavArea, NavDestination } from "../../types/enums.ts";

type NavItemExtended = NavItemResponse & { subNavs: NavItemExtended[] | undefined; applicationTemplateId?: string | undefined };
type NavItemPropsExtended = NavItemProps & { leftColor?: string | undefined };

function getDynamicNavItem(
    navItem: NavItemResponse,
    engagementId: string,
    leftIndent: string | undefined,
    appColor?: string | undefined,
): NavItemPropsExtended {
    const to = navItem.destination === NavDestination.Iframe ? `/engagement/${engagementId}/navItem/${navItem.id}` : navItem.href;
    return { to, leftIcon: <IconAppWindow size="1rem" />, text: navItem.linkText, leftColor: appColor, itemDefaults: leftIndent ? { indent: leftIndent } : {} };
}

export interface EngagementAppItems {
    items: Array<NavGroup | NavItemProps>;
    isLoading: boolean;
    error?: string | undefined;
}

export const useEngagementAppItems = (engagementId: string): EngagementAppItems => {
    const { data: navItems, isLoading: isNavItemsLoading } = useNavItems();
    const { data: engagementNavItems, isLoading: isEnagementItemsLoading } = useNavItemsForEngagement(engagementId);
    const { data: applications, isLoading: isApplicationsLoading } = useApplications(engagementId);

    const isLoading = isNavItemsLoading || isEnagementItemsLoading || isApplicationsLoading;

    const engagementItems = useMemo(
        () => [...(navItems ?? []).filter(item => item.area === NavArea.Engagement), ...(engagementNavItems ?? [])],
        [navItems, engagementNavItems],
    );

    const getNavItem = (navItem: NavItemExtended) => {
        const hasChildren = navItem.subNavs && navItem.subNavs.length > 0;
        if (hasChildren) {
            const firstSubItem = navItem.subNavs![0];
            const appColor = applications?.find(x => x.applicationTemplateId === firstSubItem.applicationTemplateId)?.appColor;

            const navGroup: NavGroup = {
                isGroup: true,
                isAccordion: true,
                groupColor: appColor ?? "green.500",
                groupMargin: "0",
                groupPadding: "0 1rem 0 0",
                itemDefaults: { hoverColor: "greenAlpha.400", activeColor: "green.500" },
                groupHeading: (
                    <Flex px="1rem" py=".5rem" gap=".5rem" align="center" color="white">
                        <IconAppWindow size="16px" />
                        {navItem.linkText}
                    </Flex>
                ),
                items: [],
            };

            if (navItem.subNavs && navItem.subNavs.length) {
                for (const subNavItem of navItem.subNavs) {
                    navGroup.items.push(getDynamicNavItem(subNavItem, engagementId, "0.5rem"));
                }
            }

            return navGroup;
        } else {
            const appColor = applications?.find(x => x.applicationTemplateId === navItem.applicationTemplateId)?.appColor || undefined;
            return getDynamicNavItem(navItem, engagementId, undefined, appColor);
        }
    };

    const groupList: Array<NavGroup | NavItemProps> = [];

    if (engagementItems && engagementItems.length > 0) {
        const headerGroup: NavGroup = {
            isGroup: true,
            groupColor: "transparent",
            groupMargin: "1rem 0 0 0",
            itemDefaults: { hoverColor: "greenAlpha.400", activeColor: "green.500" },
            groupHeading: "Applications",
            items: [],
        };
        groupList.push(headerGroup);
    }

    if (engagementItems.length) {
        for (const navItem of engagementItems) {
            const groupItem = getNavItem(navItem as NavItemExtended);
            groupList.push(groupItem);
        }
    }

    return { items: groupList, isLoading };
};

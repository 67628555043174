export enum FeedbackType {
    Feedback = 1,
    Bug = 2,
    Question = 3,
    Suggestion = 4,
}

export const FeedbackTypeOptions = Object.values(FeedbackType).filter(v => !isNaN(v as FeedbackType)) as FeedbackType[];

type FeedbackTypeDetails = { displayName: string };

export const FeedbackTypeLookup: Record<FeedbackType, FeedbackTypeDetails> = {
    [FeedbackType.Feedback]: { displayName: "Feedback" },
    [FeedbackType.Bug]: { displayName: "Bug" },
    [FeedbackType.Question]: { displayName: "Question" },
    [FeedbackType.Suggestion]: { displayName: "Suggestion" },
};

export const displayFeedbackType = (FeedbackTypeValue: FeedbackType) => {
    return FeedbackTypeLookup[FeedbackTypeValue].displayName;
};

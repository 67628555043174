import { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button, BreadcrumbLink, Card, CardBody, Flex, FormControl, FormLabel, CardHeader } from "@chakra-ui/react";
import { EngagementSearchResponse, useEngagementSearchQueryFn } from "../../api/index.ts";
import { AutoCompleteSingle } from "am-tax-fe-core";
import debounce from "lodash.debounce";
import { Page } from "../Page.tsx";

export const UserManagementPage: FunctionComponent = () => {
    // ---- Setup Engagement Search ----
    const engagementSearchFn = useEngagementSearchQueryFn();
    const searchEngagements = debounce((searchText: string, callback): void => {
        if (searchText.length > 2) {
            engagementSearchFn({ searchText }).then(engagements => {
                callback(
                    engagements.map(engagement => ({
                        value: engagement,
                        label: `${engagement.projectCode} - ${engagement.engagementName}`,
                    })),
                );
            });
        }
    }, 250);
    const [, setEngagementResponse] = useState<EngagementSearchResponse | undefined>(undefined);

    // const provisionEngagement = async () => {
    //     const engagementQuery = useEngagement(engagementResponse?.engagementId);
    //     const engagement = engagementQuery.data;
    //     const createEngagementQuery = useCreateEngagement();

    //     const args: PostEngagementArgs = {
    //         clientId: engagement?.clientId,
    //         engagementTypeIds: engagement?.types.forEach(t => t.id) || [],
    //         engagementName: engagement?.name || "",
    //         projectCode: engagement?.projectCode || "",
    //         allowedDomains: [],
    //         regionId: engagement?.regionId,
    //     };

    //     const data = await createEngagementQuery.mutateAsync(args);
    //     toast({
    //         title: "Saved",
    //         description: "Settings Saved.",
    //         status: "success",
    //         duration: 3000,
    //         isClosable: true,
    //     });
    // };

    return (
        <Page
            title="User Management Page"
            gutter={
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={NavLink} to="/admin/dtsadmin">
                            DTS Admin
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink>Add to Engagement/Project</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            }
        >
            <Flex direction={"column"} mt={"2rem"} gap={"1rem"}>
                <Card>
                    <CardHeader bgGradient="linear(to-br, blue.900, blue.700)"></CardHeader>
                    <CardBody borderRadius={"lg"}>
                        <FormControl>
                            <FormLabel>Engagement</FormLabel>
                            <FormLabel fontWeight={"400"}>Search for engagements</FormLabel>
                            <AutoCompleteSingle
                                loadOptions={(searchText, callback) => searchEngagements(searchText, callback)}
                                placeholder="Engagement code or name..."
                                noOptionsMessage={obj =>
                                    obj.inputValue.length > 2 ? "No Engagements Found." : "Type at least 3 character to initiate search."
                                }
                                loadingMessage={obj =>
                                    obj.inputValue.length > 2 ? "Searching for Engagements..." : "Type at least 3 character to initiate search."
                                }
                                onChange={opt => setEngagementResponse(opt?.value as unknown as EngagementSearchResponse)}
                            />
                        </FormControl>
                    </CardBody>
                </Card>
                <Flex justifyContent="flex-end" width={"full"}>
                    <Button mr={3} isDisabled={false} variant={"ghost"} loadingText={"Saving"}>
                        Add to Engagement
                    </Button>
                </Flex>
            </Flex>
            <Flex direction={"column"} mt={"5rem"} gap={"1rem"}>
                <Card>
                    <CardHeader bgGradient="linear(to-br, blue.900, blue.700)"></CardHeader>
                    <CardBody borderRadius={"lg"}>
                        <FormControl>
                            <FormLabel>Project</FormLabel>
                            <FormLabel fontWeight={"400"}>Search for projects</FormLabel>
                            <AutoCompleteSingle
                                loadOptions={(searchText, callback) => searchEngagements(searchText, callback)}
                                placeholder="Project code or name..."
                                noOptionsMessage={obj => (obj.inputValue.length > 2 ? "No Project Found." : "Type at least 3 character to initiate search.")}
                                loadingMessage={obj =>
                                    obj.inputValue.length > 2 ? "Searching for Project..." : "Type at least 3 character to initiate search."
                                }
                                //onChange={opt => openModal(opt ? (opt.value as string) : undefined)}
                            />
                        </FormControl>
                    </CardBody>
                </Card>
                <Flex justifyContent="flex-end" width={"full"}>
                    <Button mr={3} isDisabled={false} variant={"ghost"} loadingText={"Saving"}>
                        Add to Project
                    </Button>
                </Flex>
            </Flex>
        </Page>
    );
};

import { FunctionComponent, useEffect, useRef } from "react";
import sanitizeHtml from "sanitize-html";
import Quill from "quill";
import "quill-mention";
import { Box } from "@chakra-ui/react";

import "quill/dist/quill.bubble.css";
import "quill-mention/dist/quill.mention.css";

export interface Props {
    html: string;
}

const RichTextLabel: FunctionComponent<Props> = ({ html }: Props) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill | null>(null);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));
            const quill = new Quill(editorContainer, {
                theme: "bubble",
                modules: {
                    toolbar: false,
                },
                readOnly: true,
            });
            quillRef.current = quill;

            quill.root.innerHTML = sanitizeHtml(html, {
                allowedAttributes: {
                    li: ["data-list"],
                    a: ["href", "target", "rel"],
                    span: ["class"],
                },
            });
        }

        return () => {
            quillRef.current = null;
            if (container) {
                container.innerHTML = "";
            }
        };
    }, [html]);

    const sxStyles = {
        ".ql-container": {
            fontSize: "1rem",
        },
        ".ql-bubble .ql-editor a": {
            color: "#06c",
        },
    };

    return (
        <Box sx={sxStyles}>
            <div ref={containerRef} />
        </Box>
    );
};

export default RichTextLabel;

import { FunctionComponent, useMemo, useRef, useState } from "react";
import { Page } from "../Page";
import { Box, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, Button, HStack, Icon, Spacer } from "@chakra-ui/react";
import { AgGridInfiniteScroll, AgGridInfiniteScrollApi, AutoCompleteSingle, IconButton, Option, Shimmer, useConfirm, useToast } from "am-tax-fe-core";
import { IconDownload, IconTrash } from "@tabler/icons-react";
import {
    DeleteUserFromEngagementArgs,
    DeleteUserFromProjectArgs,
    ProjectSearchResponse,
    SearchUserProjectsArgs,
    useDeleteUserFromEngagement,
    useDeleteUserFromProject,
    UserProjectSummaryResponse,
    useSearchUsersQueryFn,
    useUserProjectSearchQueryFn,
} from "../../api";
import { debounce } from "lodash";
import { ColDef, ColSpanParams, IDatasource, IGetRowsParams, ValueGetterParams } from "ag-grid-community";
import { ProjectFormat, displayProjectFormat } from "../../enums/ProjectFormat";
import { EngagmentIcon, ProjectIcon } from "../../util/engagementUtils";
import { Mutation } from "../../util/queryUtils";
import { useNavigate } from "react-router-dom";

export const DTSAdminPage: FunctionComponent = () => {
    const toast = useToast();
    const navigate = useNavigate();

    const [userAutoCompleteOption, setUserAutoCompleteOption] = useState<Option<string>>();
    const deleteEngagementUserQuery = useDeleteUserFromEngagement();
    const deleteProjectUserQuery = useDeleteUserFromProject();
    const searchUsersQueryFn = useSearchUsersQueryFn();
    const searchUserProjectQueryFn = useUserProjectSearchQueryFn();

    const deleteEngagementUserMutation = useMemo(() => new Mutation(deleteEngagementUserQuery), [deleteEngagementUserQuery]);
    const deleteProjectUserMutation = useMemo(() => new Mutation(deleteProjectUserQuery), [deleteProjectUserQuery]);

    const searchUser = debounce((searchText: string, callback): void => {
        searchUsersQueryFn({ searchText }).then(users => {
            const userSearchResults = users.map(user => {
                return {
                    value: user.id,
                    label: `${user.firstName} ${user.lastName} - ${user.email}`,
                };
            });
            callback([...userSearchResults]);
        });
    }, 250);
    const { confirm, ConfirmDialog } = useConfirm({
        title: "Remove User?",
        prompt: "Are you sure you want to remove this user?",
    });
    const deleteUser = async (userId: string, engagementId: string, projectId: string) => {
        const result = await confirm();
        if (result) {
            if (projectId != null) {
                const args = deleteProjectUserMutation.updateArgs({ engagementId: engagementId ?? "", projectId: projectId ?? "", userId: userId });
                await deleteProjectUserMutation.query.mutateAsync(args);
                toast({ title: "Success", description: "User removed", status: "success", duration: 5000, isClosable: true });
            } else {
                const args = deleteEngagementUserMutation.updateArgs({ engagementId: engagementId ?? "", userId: userId });
                deleteEngagementUserMutation.query.mutateAsync(args);
                toast({ title: "Success", description: "User removed", status: "success", duration: 5000, isClosable: true });
            }
        }
    };

    function goToUserMgtPage() {
        navigate(`/admin/provisionEngagement/engagement/${userAutoCompleteOption?.value}`, { relative: "path" });
    }

    const onUserSelected = async (option: Option<string>) => {
        setUserAutoCompleteOption(option);
    };
    const gridApiRef = useRef<AgGridInfiniteScrollApi<ProjectSearchResponse>>();
    const datasource: IDatasource = useMemo(
        () => ({
            getRows: async (params: IGetRowsParams) => {
                const top = params.endRow - params.startRow;
                const skip = params.startRow;

                const projectSearchParams: SearchUserProjectsArgs = {
                    userId: userAutoCompleteOption?.value,
                };
                try {
                    const engagements = await searchUserProjectQueryFn(projectSearchParams);
                    let lastRow = -1;
                    if (engagements.length < top) {
                        lastRow = skip + engagements.length;
                    }
                    params.successCallback(engagements, lastRow);
                } catch (error) {
                    params.failCallback();
                    throw error;
                }
            },
        }),
        [userAutoCompleteOption],
    );
    const columnDefs: ColDef<ProjectSearchResponse>[] = useMemo(
        () => [
            {
                headerName: "Type",
                field: "projectFormat",
                colSpan: (params: ColSpanParams) => {
                    if (params.data) {
                        return 1;
                    } else {
                        return columnDefs.length;
                    }
                },
                valueGetter: ({ data }: ValueGetterParams<ProjectSearchResponse>) => {
                    if (data?.projectFormat) {
                        return displayProjectFormat(data.projectFormat);
                    }
                },
                cellRenderer: (props: { data: ProjectSearchResponse }) => {
                    const response = props.data;
                    if (response) {
                        return (
                            <HStack alignItems={"center"}>
                                <Box>
                                    <Icon as={response.projectFormat === ProjectFormat.Engagement ? EngagmentIcon : ProjectIcon} />
                                </Box>
                                <Box>{displayProjectFormat(response.projectFormat)}</Box>
                            </HStack>
                        );
                    } else {
                        return (
                            <Shimmer height="unset" textAlign={"center"} p={0} marginX={"-20px"}>
                                Loading rows...
                            </Shimmer>
                        );
                    }
                },
            },
            {
                field: "clientName",
            },
            {
                field: "engagementName",
            },
            {
                field: "engagementCode",
            },
            {
                field: "projectCode",
            },
            {
                field: "projectName",
            },
            {
                headerName: "Action",
                sortable: false,
                cellRenderer: (props: { data: UserProjectSummaryResponse }) => {
                    return (
                        <IconButton
                            icon={<IconTrash />}
                            aria-label={"delete"}
                            variant={"naked"}
                            onClick={() => {
                                return deleteUser(props.data.userId ?? "", props.data.engagementId ?? "", props.data.projectId ?? "");
                            }}
                        />
                    );
                },
            },
        ],
        [],
    );

    return (
        <Page title="DTS Administration ">
            <Flex flexDirection={"column"} gap={"2rem"} minHeight={"100%"} align={"end"}>
                <Button fontWeight={"normal"} variant={"naked"} bottom={"1rem"} leftIcon={<IconDownload />} alignItems={"end"}>
                    Export Users
                </Button>
                <Spacer />
            </Flex>
            <Flex flexDirection={"column"} gap={"2rem"} minHeight={"100%"}>
                <Card>
                    <CardHeader bgGradient="linear(to-r, blue.500, blue.100)"></CardHeader>
                    <CardBody>
                        <Flex mb={"1rem"} gap={"2rem"} alignItems={"flex-end"}>
                            <Box flexGrow={1}>
                                <FormControl>
                                    <FormLabel>Search Users:</FormLabel>
                                    <AutoCompleteSingle
                                        loadOptions={searchUser}
                                        value={userAutoCompleteOption}
                                        onChange={onUserSelected}
                                        placeholder="Type the name or email of the user to search"
                                        noOptionsMessage={(obj: { inputValue: string }) =>
                                            obj.inputValue.length > 2 ? "No Users Found." : "Type at least 2 characters to initiate user search."
                                        }
                                        loadingMessage={() => "Searching for Users..."}
                                    ></AutoCompleteSingle>
                                </FormControl>
                                <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                                    <Button
                                        mr={3}
                                        isDisabled={!userAutoCompleteOption?.value.length}
                                        onClick={() => goToUserMgtPage()}
                                        variant={"ghost"}
                                        loadingText={"Saving"}
                                    >
                                        Add to Engagement/Project
                                    </Button>
                                </Flex>
                            </Box>
                        </Flex>
                    </CardBody>
                </Card>
                {userAutoCompleteOption && (
                    <Box>
                        <FormControl>
                            <FormLabel>Provisioned Engagements/Projects:</FormLabel>
                        </FormControl>

                        <AgGridInfiniteScroll
                            hideGrid={false}
                            gridApiRef={gridApiRef}
                            datasource={datasource}
                            columnDefs={columnDefs}
                            autoSizeStrategy={{ type: "fitGridWidth" }}
                            fillVerticalSpace
                            suppressMultiSort
                            defaultColDef={{
                                sortable: false,
                                resizable: true,
                                filter: false,
                            }}
                        />
                        <ConfirmDialog />
                    </Box>
                )}
            </Flex>
        </Page>
    );
};

import { Participant } from "../../../api";

export const getMentionModule = (participants: Participant[]) => ({
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@", "#"],
    source: function (searchTerm: string, renderList: (opt: { id: string; value: string }[], q: string) => void) {
        const values = participants.map(x => ({ id: x.user.id, value: `${x.user.firstName} ${x.user.lastName}` }));
        if (searchTerm.length === 0) {
            renderList(values, searchTerm);
        } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) {
                if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                    matches.push(values[i]);
                }
            }
            renderList(matches, searchTerm);
        }
    },
});

export const getStandardToolbar = () => ({
    container: [
        [{ header: "1" }, { header: "2" }],
        ["bold", "italic", "underline", "blockquote"],
        // [{ color: [] }],
        [
            { list: "ordered" },
            { list: "bullet" },
            // { indent: "-1" },
            // { indent: "+1" },
        ],
        ["link"],
        ["clean"],
    ],
});

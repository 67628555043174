import { FunctionComponent, useRef, useState } from "react";
import { Box, IconButton, Button, Flex, Heading, Tooltip, LinkBox, LinkOverlay, Alert, AlertIcon, AlertDescription, AlertTitle } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Report, useEngagementReports, useEngagementToggleReportDashboard, useProjectReports, useProjectToggleReportDashboard } from "../../api/index.ts";
import { IconPlus } from "@tabler/icons-react";
import { AreaPermissions, ReportArea, SecurityArea } from "../../auth/index.ts";
import { Page } from "../Page.tsx";

import EditReportDialog from "./EditReportDialog.tsx";

import { IconEdit, IconEye, IconEyeOff } from "@tabler/icons-react";
import { PageLoading } from "am-tax-fe-core";

export type Props = {
    engagementId: string;
    projectId?: string;
    areaPermissions: AreaPermissions<SecurityArea>;
    enableToggleDashboard?: boolean;
    isInternaluser: boolean;
};

export const BaseReportsPage: FunctionComponent<Props> = ({ engagementId, projectId, areaPermissions, isInternaluser }) => {
    const focusAfterClosingRef = useRef(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedReport, setSelectedReport] = useState<Report | undefined>();

    const { mutate: toggleProjectPublish, isPending: toggleProjectPublishPending } = useProjectToggleReportDashboard();
    const { mutate: toggleEngagementPublish, isPending: toggleEngagementPublishPending } = useEngagementToggleReportDashboard();
    const { data: engagementData, isLoading: isEngLoadingReports } = useEngagementReports(engagementId, !projectId);
    const { data: projectData, isLoading: isProjLoadingReports } = useProjectReports(engagementId, projectId!);
    const reportData = projectId ? projectData : engagementData;

    const togglePublishPending = projectId ? toggleProjectPublishPending : toggleEngagementPublishPending;
    const isLoadingReports = projectId ? isProjLoadingReports : isEngLoadingReports;

    const handleTogglePublish = (report: Report) => {
        const args = { engagementId, ...report, isPublishedToClient: !report.isPublishedToClient };
        setSelectedReport(report);
        if (!projectId) {
            toggleEngagementPublish(args);
        } else toggleProjectPublish({ ...args, projectId });
    };

    const handleEditReport = (report?: Report) => {
        setSelectedReport(report);
        setShowEditDialog(true);
    };

    const getToolbar = (report: Report) => (
        <Flex pl="1rem" gap=".5rem">
            {areaPermissions.has(ReportArea.update) && (
                <>
                    <Tooltip label={report.isPublishedToClient ? "Hide report from clients" : "Hidden from clients"}>
                        <IconButton
                            aria-label="Hide comment from clients"
                            variant="naked"
                            icon={report.isPublishedToClient ? <IconEye size={24} /> : <IconEyeOff size={24} />}
                            onClick={() => handleTogglePublish(report)}
                            isLoading={report.id === selectedReport?.id && togglePublishPending}
                        />
                    </Tooltip>
                    <Tooltip label="Edit report">
                        <IconButton aria-label="Edit report" variant="naked" icon={<IconEdit size={24} />} onClick={() => handleEditReport(report)} />
                    </Tooltip>
                </>
            )}
        </Flex>
    );

    const tiles = reportData?.map(report => {
        return (
            <LinkBox
                bg={report.isPublishedToClient ? "inherit" : "blackAlpha.50"}
                as="article"
                key={report.id}
                width={["100%", null, "24rem"]}
                minHeight="12rem"
                borderWidth="1px"
                borderTop="2px solid purple"
                boxShadow="3px 3px 3px 0px rgba(0,0,0,0.1)"
                rounded="md"
                p="1rem"
            >
                <Flex justifyContent="center" alignItems="flex-start" mb="1rem">
                    <Heading fontSize="lg" fontWeight="500" width="100%" mb={0}>
                        <LinkOverlay as={Link} to={`report/${report.id}`}>
                            {report.title}
                        </LinkOverlay>
                    </Heading>
                    {getToolbar(report)}
                </Flex>
                <Box overflowX="clip">{report.description}</Box>
            </LinkBox>
        );
    });

    const tileContainer = (
        <Flex gap="1rem" wrap="wrap">
            {tiles && tiles.length > 0 ? (
                tiles
            ) : (
                <>
                    {!isInternaluser ? (
                        <Alert status="info" variant="subtle" flexDirection="column">
                            <Flex ml="1rem" alignItems="center" width="100%">
                                <AlertIcon boxSize="40px" mr="1rem" />
                                <Box>
                                    <AlertTitle mt={4} mb={1} fontSize="lg">
                                        Start using Reports
                                    </AlertTitle>
                                    <AlertDescription textAlign="left">
                                        There are no active Reports enabled for this engagement. Please contact Engagement/Project Administrator to enable
                                        reports.
                                    </AlertDescription>
                                </Box>
                            </Flex>
                        </Alert>
                    ) : (
                        <Alert status="info" variant="subtle" flexDirection="column">
                            <Flex ml="1rem" alignItems="center" width="100%">
                                <AlertIcon boxSize="40px" mr="1rem" />
                                <Box>
                                    <AlertTitle mt={4} mb={1} fontSize="lg">
                                        Start using Reports
                                    </AlertTitle>
                                    <AlertDescription textAlign="left">
                                        There are no reports yet. Add a new report by pressing the button at the top of the screen.
                                    </AlertDescription>
                                </Box>
                            </Flex>
                        </Alert>
                    )}
                </>
            )}
        </Flex>
    );

    if (isLoadingReports) {
        return <PageLoading text={"Loading Reports..."} />;
    }

    return (
        <Page title="Reports">
            {!!isInternaluser && (
                <Flex mb={"1rem"} gap={"2rem"} justifyContent="flex-end">
                    {areaPermissions.has(ReportArea.create) && (
                        <Button leftIcon={<IconPlus />} onClick={() => handleEditReport()} px={"1rem"} ref={focusAfterClosingRef}>
                            Add Report
                        </Button>
                    )}
                </Flex>
            )}
            {tileContainer}
            <EditReportDialog
                engagementId={engagementId}
                projectId={projectId}
                report={selectedReport}
                show={showEditDialog}
                closeDialog={() => setShowEditDialog(false)}
                focusAfterClosingRef={focusAfterClosingRef}
            />
        </Page>
    );
};

import { useApiContext } from "am-tax-fe-core";
import { useMemo } from "react";

export interface UseHasAccess {
    identity: Identity | undefined;
    hasRole: (expectedRole?: ApplicationRole) => boolean;
}

export function useHasAccess(): UseHasAccess {
    const { accessToken, idToken } = useApiContext();

    const apiId = accessToken ? parseJwt(accessToken) : undefined,
        appId = idToken ? parseJwt(idToken) : undefined;

    let identity: Identity | undefined;
    if (appId == undefined && apiId == undefined) {
        identity = undefined;
    } else {
        const id = { ...appId, ...apiId } as IdentityToken;
        identity = {
            givenname: id.givenname,
            surname: id.surname,
            roles: id.roles,
            mail: id.mail,
            oid: id.oid,
        };
    }

    const hasRole = useMemo(
        () =>
            (expectedRole?: ApplicationRole): boolean => {
                if (identity === undefined || identity.roles === undefined || identity.roles.length === 0) {
                    // no roles at all
                    return false;
                }

                if (expectedRole === undefined) {
                    // no role was specified, so any role works
                    return true;
                }

                const roles = identity.roles;
                switch (expectedRole) {
                    case ApplicationRole.developer:
                        return roles.some(r => r === ApplicationRole.developer);
                    case ApplicationRole.admin:
                        return roles.some(r => r === ApplicationRole.admin);
                    case ApplicationRole.contributor:
                        return roles.some(r => r === ApplicationRole.contributor || r === ApplicationRole.admin);
                    case ApplicationRole.reader:
                        return roles.some(r => r === ApplicationRole.reader || r === ApplicationRole.contributor || r === ApplicationRole.admin);
                }
            },
        [identity],
    );

    return {
        identity,
        hasRole,
    };
}

export enum ApplicationRole {
    admin = "urn:am:taxand:role:admin",
    contributor = "urn:am:taxand:role:contributor",
    reader = "urn:am:taxand:role:reader",
    developer = "urn:am:taxand:role:developer",
}

export interface IJwtToken {
    /*
    Audience - who the token is intended for
     */
    aud: string;
    /*
    Issuer - who created
     */
    iss: string;
    /*
    Issued at (seconds since Unix epoch)
     */
    iat: number;
    /*
    Not valid before (seconds since Unix epoch)
     */
    nbf: number;
    /*
    Expiration time (seconds since Unix epoch)
     */
    exp: number;

    /*
        Authorized Party
     */
    azp: string;

    /*
    Subject - whom the token refers to 
     */
    sub: string;
}

export interface IAzureAdJwt extends IJwtToken {
    /*
    azure-internal - do not use
     */
    aio: string;
    /*
    Authentication method:
    0 - public client
    1 - clientId / secret
    2 - certificate
     */
    azpacr: string;
    /*
    the identity provider that authenticated the subject of the token
     */
    idp: string;
    /*
    The immutable identifier for the requestor
     */
    oid: string;
    preferred_username: string;
    /*
    azure-internal - do not use
     */
    rh: string;
    /*
    Scope
    space separated list
     */
    scp: string;
    /*
    Tenant Id
     */
    tid: string;
    /*
    Unique token Id - equivalent to JTI
     */
    uti: string;

    /*
    Indicates the version of the access token. (1.0 or 2.0)
     */
    ver: string;
}

export interface IdentityToken extends IAzureAdJwt {
    givenname?: string;
    surname?: string;
    roles?: ApplicationRole[];
    mail?: string;
}

export type Identity = Pick<IdentityToken, "givenname" | "surname" | "roles" | "mail" | "oid">;

function parseJwt(token: string): IdentityToken {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
    );

    return JSON.parse(jsonPayload) as IdentityToken;
}

export const RoleNames: Record<ApplicationRole, string> = {
    [ApplicationRole.admin]: "Admin",
    [ApplicationRole.contributor]: "Contributor",
    [ApplicationRole.reader]: "Reader",
    [ApplicationRole.developer]: "Developer",
};

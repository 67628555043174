export enum ProjectFormat {
    Project = 1,
    Engagement = 2,
}

export const ProjectFormats = Object.values(ProjectFormat).filter(v => !isNaN(v as ProjectFormat)) as ProjectFormat[];

type ProjectFormatDetails = { displayName: string };

export const ProjectFormatLookup: Record<ProjectFormat, ProjectFormatDetails> = {
    [ProjectFormat.Project]: { displayName: "Project" },
    [ProjectFormat.Engagement]: { displayName: "Engagement" },
};

export const displayProjectFormat = (ProjectFormat: ProjectFormat) => {
    return ProjectFormatLookup[ProjectFormat].displayName;
};

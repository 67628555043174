import { FunctionComponent } from "react";

import { Badge, Box, Card, Heading, HStack, Progress, Spacer, VStack } from "@chakra-ui/react";
import { IconActivity, IconFileReport, IconForbid } from "@tabler/icons-react";
import { EngagementSummary } from "../../api/api.ts";
import { formatTimestampStr } from "am-tax-fe-core";
import { useNavigate } from "react-router-dom";

type EngagementSummaryCardProps = {
    summary: EngagementSummary;
};

export const EngagementSummaryCard: FunctionComponent<EngagementSummaryCardProps> = ({ summary }) => {
    const navigate = useNavigate();
    return (
        <Card
            px={"1.5rem"}
            py={".5rem"}
            cursor={"pointer"}
            bg={"gray.50"}
            _hover={{ bg: "gray.100" }}
            onClick={() => navigate(`/engagement/${summary.engagementId}/dashboard`)}
        >
            <HStack gap={"2rem"} spacing={"1rem"}>
                <Heading fontSize={"1rem"} width={"250px"}>
                    {summary.projectCode} - {summary.engagementName}
                </Heading>
                <Spacer />
                <VStack spacing={"14px"}>
                    <HStack>
                        <IconFileReport size="1rem" />
                        <Heading variant={"info"}>Requests Fulfilled</Heading>
                    </HStack>
                    <Progress value={(100 * summary.requestsFulfilled) / summary.requestsTotal} colorScheme={"purple"} size={"lg"} width={"100%"} />
                </VStack>
                <VStack width={"200px"} alignItems={"start"}>
                    <HStack>
                        <IconForbid size="1rem" />
                        <Heading variant={"info"}>Blockers</Heading>
                    </HStack>
                    <HStack>
                        {summary.requestsOverdue > 0 ? (
                            <>
                                <label>Overdue Requests</label>
                                <Spacer />
                                <Badge colorScheme={"red"} fontSize={"sm"} px={"1rem"}>
                                    {summary.requestsOverdue}
                                </Badge>
                            </>
                        ) : (
                            <label>None</label>
                        )}
                    </HStack>
                </VStack>
                <VStack width={"200px"} alignItems={"start"}>
                    <HStack>
                        <IconActivity size="1rem" />
                        <Heading variant={"info"}>Activity</Heading>
                    </HStack>
                    <HStack>
                        {summary.lastAccessedOn ? (
                            <>
                                <label>Last Accessed</label>
                                <Spacer />
                                <Box>{formatTimestampStr(summary.lastAccessedOn, { appendTime: false })}</Box>
                            </>
                        ) : (
                            <label>None</label>
                        )}
                    </HStack>
                </VStack>
            </HStack>
        </Card>
    );
};

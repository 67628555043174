import { Role, useCurrentUser, useMyRoleOnEngagement, useMyRoleOnProject, usePermissionsByRole, User } from "../api";
import { useMemo } from "react";
import {
    AppPermissions,
    AreaPermissions,
    SecurityArea,
    PermissionsByAreaType,
    Permission,
    roleCanSeeArea,
    roleHasPermission,
    roleHasAnyPermission,
} from "../auth";
import { UseQueryResult } from "@tanstack/react-query";

const emptyPermissions = {};

export function useAppPermissions(enabled = true): AppPermissions {
    const currentUserQuery = useCurrentUser();
    return useAppPermissionsHelper(null, currentUserQuery, enabled);
}
export function useEngagementAppPermissions(engagementId: string | null | undefined, enabled = true): AppPermissions {
    const myRoleQuery = useMyRoleOnEngagement(engagementId, enabled);
    const currentUserQuery = useCurrentUser();
    return useAppPermissionsHelper(myRoleQuery, currentUserQuery);
}
export function useProjectAppPermissions(engagementId: string | null | undefined, projectId: string | null | undefined, enabled = true): AppPermissions {
    const myRoleQuery = useMyRoleOnProject(engagementId, projectId, enabled);
    const currentUserQuery = useCurrentUser();
    return useAppPermissionsHelper(myRoleQuery, currentUserQuery);
}
function useAppPermissionsHelper(myRoleQuery: UseQueryResult<Role> | null, currentUserQuery: UseQueryResult<User>, enabled = true): AppPermissions {
    const permissionsQuery = usePermissionsByRole(enabled);
    const permissionsByRole = permissionsQuery.data || emptyPermissions;

    return useMemo(() => {
        const permissions: AppPermissions = {
            loading: myRoleQuery?.isLoading || permissionsQuery.isLoading || currentUserQuery.isLoading,
            isInternal: false,
            showArea: (area: SecurityArea) => roleCanSeeArea(permissionsByRole, area, [myRoleQuery?.data?.id, currentUserQuery.data?.systemRoleId]),
            has: (permission: Permission | null) => false,
            hasAny: (...permissions: Permission[]) => false,
        };

        if (myRoleQuery?.data?.id || currentUserQuery.data?.systemRoleId) {
            permissions.isInternal = myRoleQuery?.data?.isInternal || !!currentUserQuery.data?.systemRoleId;
            permissions.has = (permission: Permission | null) =>
                roleHasPermission(permissionsByRole, permission, [myRoleQuery?.data?.id, currentUserQuery.data?.systemRoleId]);
            permissions.hasAny = (...permissions: Permission[]) =>
                roleHasAnyPermission(permissionsByRole, permissions, [myRoleQuery?.data?.id, currentUserQuery.data?.systemRoleId]);
        }
        return permissions;
    }, [
        permissionsByRole,
        permissionsQuery.isLoading,
        myRoleQuery?.data?.id,
        myRoleQuery?.data?.isInternal,
        myRoleQuery?.isLoading,
        currentUserQuery.isLoading,
        currentUserQuery.data?.systemRoleId,
    ]);
}

export function useEngagementAreaPermissions<A extends SecurityArea>(area: A, engagementId: string | null | undefined): AreaPermissions<A> {
    const myRoleQuery = useMyRoleOnEngagement(engagementId);
    const currentUserQuery = useCurrentUser();
    return useAreaPermissions(area, myRoleQuery, currentUserQuery);
}
export function useProjectAreaPermissions<A extends SecurityArea>(
    area: A,
    engagementId: string | null | undefined,
    projectId: string | null | undefined,
): AreaPermissions<A> {
    const myRoleQuery = useMyRoleOnProject(engagementId, projectId);
    const currentUserQuery = useCurrentUser();
    return useAreaPermissions(area, myRoleQuery, currentUserQuery);
}
function useAreaPermissions<A extends SecurityArea>(area: A, myRoleQuery: UseQueryResult<Role>, currentUserQuery: UseQueryResult<User>): AreaPermissions<A> {
    const permissionsQuery = usePermissionsByRole();
    const permissionsByRole = permissionsQuery.data || emptyPermissions;

    return useMemo(() => {
        const permissions: AreaPermissions<A> = {
            loading: myRoleQuery.isLoading || permissionsQuery.isLoading || currentUserQuery.isLoading,
            isInternal: false,
            showArea: roleCanSeeArea(permissionsByRole, area, [myRoleQuery.data?.id, currentUserQuery.data?.systemRoleId]),
            has: (permission: PermissionsByAreaType<A> | null) => false,
            hasAny: (...permissions: PermissionsByAreaType<A>[]) => false,
        };

        if (myRoleQuery.data?.id || currentUserQuery.data?.systemRoleId) {
            permissions.isInternal = myRoleQuery.data?.isInternal || !!currentUserQuery.data?.systemRoleId;
            permissions.has = (permission: PermissionsByAreaType<A> | null) =>
                roleHasPermission(permissionsByRole, permission, [myRoleQuery.data?.id, currentUserQuery.data?.systemRoleId]);
            permissions.hasAny = (...permissions: PermissionsByAreaType<A>[]) =>
                roleHasAnyPermission(permissionsByRole, permissions, [myRoleQuery.data?.id, currentUserQuery.data?.systemRoleId]);
        }
        return permissions;
    }, [
        myRoleQuery.isLoading,
        myRoleQuery.data?.id,
        myRoleQuery.data?.isInternal,
        currentUserQuery.isLoading,
        currentUserQuery.data?.systemRoleId,
        permissionsQuery.isLoading,
        permissionsByRole,
        area,
    ]);
}

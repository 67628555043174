import { FunctionComponent } from "react";
import useState from "react-usestateref";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Flex,
    Box,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Select,
    Textarea,
    SimpleGrid,
    Button,
    VStack,
    Spacer,
    Divider,
    Text,
    AbsoluteCenter,
} from "@chakra-ui/react";
import { DatePicker, useToast, RepeatShimmer, dropTimeFromIsoFormat, useConfirm, FileContainer } from "am-tax-fe-core";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { UseQueryResult } from "@tanstack/react-query";

import {
    Engagement,
    TaxNotice,
    Project,
    PostEngagementTaxNoticeArgs,
    PostProjectTaxNoticeArgs,
    UploadDocumentsToEngagementTaxNoticeArgs,
    UploadDocumentsToProjectTaxNoticeArgs,
    DeleteEngagementTaxNoticeArgs,
    DeleteProjectTaxNoticeArgs,
    PutEngagementTaxNoticeArgs,
    PutProjectTaxNoticeArgs,
} from "../../api";
import { Mutation } from "../../util/queryUtils.ts";
import { TAX_NOTICE_STATUS_TYPES, US_STATES } from "./utils.ts";
import DocumentListPanel from "../../components/DocumentListPanel";

export type ProjectEngagement = Project | Engagement;
export type SaveTaxNoticeArgs = PostEngagementTaxNoticeArgs | PostProjectTaxNoticeArgs | PutEngagementTaxNoticeArgs | PutProjectTaxNoticeArgs;
export type DeleteTaxNoticeArgs = DeleteEngagementTaxNoticeArgs | DeleteProjectTaxNoticeArgs;
export type UploadDocumentsToTaxNoticeArgs = UploadDocumentsToEngagementTaxNoticeArgs | UploadDocumentsToProjectTaxNoticeArgs;

export interface TaxNoticeFormProps {
    projectEngagementQuery: UseQueryResult<ProjectEngagement>;
    taxNoticeQuery: UseQueryResult<TaxNotice>;
    saveTaxNoticeMutation: Mutation<SaveTaxNoticeArgs, TaxNotice>;
    deleteTaxNoticeMutation: Mutation<DeleteTaxNoticeArgs, number>;
    uploadFileToTaxNoticeMutation: Mutation<UploadDocumentsToTaxNoticeArgs>;
    navigateBack: () => void;
    onSaveComplete: (projectOrEngagement: ProjectEngagement, taxNotice: TaxNotice, filesUploaded?: boolean) => void;
}

export const TaxNoticeForm: FunctionComponent<TaxNoticeFormProps> = ({
    projectEngagementQuery,
    taxNoticeQuery,
    saveTaxNoticeMutation,
    deleteTaxNoticeMutation,
    uploadFileToTaxNoticeMutation,
    navigateBack,
    onSaveComplete,
}) => {
    const toast = useToast();

    const { engagementId, projectId } = useParams();

    const [uploading, setUploading] = useState(false);
    const [fileQueue, setFileQueue] = useState<FileContainer[]>([]);
    const [, setNewNoticeJustSaved, newNoticeJustSavedRef] = useState<TaxNotice | undefined>(undefined);

    const projectOrEngagement = projectEngagementQuery.data;

    const currentDate = new Date();
    const year = Number(currentDate.getFullYear());

    const taxNotice = taxNoticeQuery.data;

    const formValues: Partial<SaveTaxNoticeArgs> = {
        taxPayerId: taxNotice?.taxPayerId,
        taxYear: taxNotice?.taxYear,
        noticeId: taxNotice?.noticeId,
        noticeDate: dropTimeFromIsoFormat(taxNotice?.noticeDate) || "",
        notes: taxNotice?.notes,
        jurisdiction: taxNotice?.jurisdiction || US_STATES[0],
        status: taxNotice?.status || TAX_NOTICE_STATUS_TYPES[0],
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Partial<SaveTaxNoticeArgs>>({
        values: formValues,
    });

    const uploadFile = (fileContainer: FileContainer, taxNoticeId?: string | undefined) => {
        fileContainer.metaData = { folderId: projectOrEngagement?.clientDocumentsRootFolderId || "" };
        const args = uploadFileToTaxNoticeMutation.updateArgs({
            engagementId: engagementId ?? "",
            taxNoticeId: taxNoticeId || taxNotice?.id || "",
            fileContainer,
        });
        uploadFileToTaxNoticeMutation.query.mutate(args);
    };

    const onSubmit: SubmitHandler<Partial<SaveTaxNoticeArgs>> = async data => {
        const args = saveTaxNoticeMutation.updateArgs({
            taxNoticeId: taxNotice?.id,
            engagementId: engagementId || "",
            projectId: projectId || "",
            taxPayerId: data.taxPayerId,
            noticeId: data.noticeId,
            notes: data.notes,
            noticeDate: data.noticeDate || "",
            taxYear: data.taxYear,
            jurisdiction: data.jurisdiction,
            status: data.status,
        });

        const response = await saveTaxNoticeMutation.query.mutateAsync(args);
        if (!taxNotice) {
            setNewNoticeJustSaved(response);
        }
        if (fileQueue.length > 0) {
            setUploading(true);
            for (const fileContainer of fileQueue) {
                uploadFile(fileContainer, response.id);
            }
        } else {
            onSaveComplete(projectOrEngagement!, response as TaxNotice);
            toast({
                title: "Saved",
                description: "Request Saved.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigateBack();
        }
    };

    const { confirm, ConfirmDialog } = useConfirm({ title: "Delete the Tax Notice", prompt: "Are you sure you want to delete this tax notice?" });
    const doDelete = async () => {
        const result = await confirm();
        if (result) {
            const args = deleteTaxNoticeMutation.updateArgs({ taxNoticeId: taxNotice?.id || "", engagementId: engagementId ?? "" });
            deleteTaxNoticeMutation.query.mutate(args, {
                onSuccess: () => {
                    toast({
                        title: "Deleted",
                        description: "Request Deleted.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    navigateBack();
                },
            });
        }
    };

    const onUploadComplete = async () => {
        try {
            const currentTaxNotice = taxNotice || newNoticeJustSavedRef.current;
            onSaveComplete(projectOrEngagement!, currentTaxNotice!, true);
            setFileQueue([]);
            toast({
                title: "Saved",
                description: "Saved Request and Uploaded Files.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            if (newNoticeJustSavedRef.current) {
                setNewNoticeJustSaved(undefined);
                navigateBack();
            }
        } finally {
            setUploading(false);
        }
    };

    if (taxNoticeQuery.isLoading) {
        return (
            <VStack spacing="1px" alignItems="stretch">
                <RepeatShimmer times={5} height="40px" />
            </VStack>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
                    <FormControl isInvalid={!!errors?.noticeId}>
                        <FormLabel>Notice Title</FormLabel>
                        <Input {...register("noticeId", { required: "Notice Title is required." })} />
                        <FormErrorMessage>{errors?.noticeId?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors?.noticeDate}>
                        <FormLabel>Notice Date</FormLabel>
                        <DatePicker {...register("noticeDate", { required: "Notice Date is required." })} />
                        <FormErrorMessage>{errors?.noticeDate?.message}</FormErrorMessage>
                    </FormControl>
                </SimpleGrid>

                <Box my="1rem">
                    <FormControl>
                        <FormLabel>Notes</FormLabel>
                        <Textarea
                            {...register("notes")}
                            maxLength={4000} //is there a way to import the column definition constants here?
                        />
                    </FormControl>
                </Box>

                <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
                    <FormControl>
                        <FormLabel>Taxpayer ID</FormLabel>
                        {/*<MaskedSsnInput {...register("taxPayerId")} />*/}
                        <Input {...register("taxPayerId")} />
                    </FormControl>
                    <FormControl isInvalid={!!errors?.taxYear}>
                        <FormLabel>Tax Year</FormLabel>
                        {/*can set max to be year*/}
                        <NumberInput defaultValue={year} max={year} min={0}>
                            <NumberInputField {...register("taxYear", { required: "Tax Year is required." })} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>{errors?.taxYear?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors?.jurisdiction}>
                        <FormLabel>State/Federal Governing Body</FormLabel>
                        <Select {...register("jurisdiction", { required: "State/Federal Governing Body is required." })}>
                            {US_STATES.map(state => (
                                <option key={state} value={state}>
                                    {state}
                                </option>
                            ))}
                        </Select>
                        <FormErrorMessage>{errors?.jurisdiction?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors?.status}>
                        <FormLabel>Status</FormLabel>
                        <Select {...register("status", { required: "Status is required." })}>
                            {TAX_NOTICE_STATUS_TYPES.map(status => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                        </Select>
                        <FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
                    </FormControl>
                </SimpleGrid>

                <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                    {taxNotice && (
                        <>
                            <Button
                                variant="ghost"
                                onClick={doDelete}
                                colorScheme={"red"}
                                fontWeight={200}
                                isDisabled={uploading || saveTaxNoticeMutation.query.isPending}
                                isLoading={deleteTaxNoticeMutation.query.isPending}
                                loadingText="Deleting"
                            >
                                Delete Notice
                            </Button>
                            <Spacer />
                        </>
                    )}

                    <Button type="submit" variant={"primary"} mr={3} loadingText="Saving">
                        Save
                    </Button>
                    <Button variant="ghost" onClick={navigateBack}>
                        Cancel
                    </Button>
                </Flex>
            </form>

            <Box sx={{ position: "relative", py: 10 }}>
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                    <Text fontSize="sm">Tax Notice Files</Text>
                </AbsoluteCenter>
            </Box>

            <DocumentListPanel
                engagementId={engagementId ?? ""}
                documents={taxNotice?.documents}
                immediateUpload={!!taxNotice?.id}
                fileQueue={fileQueue}
                setFileQueue={setFileQueue}
                uploadFile={uploadFile}
                onUploadComplete={onUploadComplete}
                canUpload={true}
            />

            <ConfirmDialog />
        </>
    );
};
